import './trainings.css';
import React, { Fragment,useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardFooter, Media, Button, Form, FormGroup, Input, Pagination, PaginationItem, PaginationLink,CardBody,Label } from 'reactstrap'

import {DATE_TIME_FORMAT, Follower,Following,TotalPost} from '../../constant'
import axios from 'axios'
import { STORED_TRANSLATIONS } from '../../redux/actionTypes';
import { Loader } from 'react-bootstrap-typeahead';
import Moment from 'react-moment';
import * as General from '../../util/General'
import { getTrainings } from '../../redux/trainings/action';
import { toast } from 'react-toastify';
import NoAccess from '../../components/reuse/noaccess';

const TrainingsList = (props) => {
  
  const userLocale = General.getLocale();
  const dispatch = useDispatch();

  const userInfo = useSelector(({auth}) => auth.authUser);
  const trainingsData = useSelector(({trainings}) => trainings.trainingsData);
  const addTrainingsRreponse = useSelector(({trainings}) => trainings.addTrainingsData);

  const trans = useSelector(({translation}) => translation.translationsData);
  const userIsAdmin = useSelector(({auth}) => auth.userIsAdmin);

  //SEARCH
  const [searchText, setSearchText] = useState('');
  var searchKeyword = "";

  //DATA
  const [curPage, setCurPage] = useState(0);
  const [email, setEmail] = useState(userInfo != null ? userInfo.email : "");
  const [descr, setDescr] = useState("");
  const [addTrainingsData, setAddTrainingsData] = useState(null);



  useEffect(() => {
    getTrainingssData();
}, []);

 
  const getTrainingssData = () => {
    let postData = {page: curPage+1,sort: {"_id": -1},query: {},searchKeyword: searchKeyword}
    dispatch(getTrainings(postData));
}

  useEffect(() => {
    getTrainingssData();
  }, [curPage]);


  const addEditTrainingsModaltoggle = () => {

  }

  const handleSearchKeyword = async (keyword)   => {
    searchKeyword = keyword;
    setSearchText(keyword);
    getTrainingssData();
  }

  const handleTrainingsEditClick = (e,user) => {
    e.preventDefault();
    // history.push(`/app/users/userEdit/${user.nickname}`);
  }

  const removeTrainings = (user)=> {

  }

  const handleFeedBackAnswer = (e,item) => {
    e.preventDefault();
    if ( item != null){
      window.location = `mailto:${item.email}`
    }
  }

  const handlePageClick = (e,newPage) => {
    e.preventDefault();
    setCurPage(newPage);
  }




  return (
    <Fragment>
      <Breadcrumb parent={trans.trainings_title} title={trans.trainings_title} />
      <Container fluid={true}>


      {!userIsAdmin ?
       <Col md="12">
       <NoAccess/>
       </Col>
       : 

      <Row>
    

      {userIsAdmin && trainingsData != null ? 
            <Col md="12">
              <Card>
                <CardHeader >
                  <Row>
                  <Col xl="12" sm="12"> 
                    <div style={{alignItems: 'center',}}> 
                     <h4 className="card-title mb-0 font-weight-bold" style={{float: "left"}}>{trans.trainings_title}</h4>
                        
                    </div>
                  </Col>
                  <br/>
                  <br/>
                 
                    </Row>
                </CardHeader>
                <div className="table-responsive">
                <table className="table card-table table-vcenter text-nowrap">
                    <thead>
                      <tr className="_table_trainings_item">
                        <th key={"title"}>{trans.training_name}</th>
                        <th key={"access_type"}>{trans.training_is_private}</th>
                        <th key={"active"}>{trans.value_status}</th>
                        <th key={"price"}>{trans.value_price}</th>
                        
                      </tr>
                    </thead>
                    <tbody>

                      {trainingsData.trainings.map((item,i) => {
                        // let values = item.values.map((value,valIndex) => {
                        //    return ( <span key={`trans_value_${i}_val_${valIndex}`} className="_translation_value">{`${value.langLoc} - ${value.value}`}</span>)
                        // })

                        var privateText = item.access_type == 1 ? trans.value_yes : trans.value_no;
                        var active = item.active ? trans.value_status_true : trans.value_status_true; 

                        return (<tr  key={`trainings_item_${i}`} className="_table_trainings_item">
                          <td>{item.title}</td>
                          <td>{privateText}</td>
                          <td>{active}</td>
                          <td>{item.price}€</td>
                        </tr>)
                      }
                      )}
                      
                    </tbody>
                  </table>
                </div>
              </Card>
            </Col>
             : <Loader/>}
          </Row>
}
      </Container>
    </Fragment>
  );
}

export default TrainingsList;