import React from 'react';
import {ConnectedRouter} from 'connected-react-router'
import {Provider} from 'react-redux';
import {Route, Switch} from 'react-router-dom';

import configureStore, {history} from './store';
import Root from './root';

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import { STRIPE_KEY } from './s_config';


export const store = configureStore();


// Initialize Stripe
const stripePromise = loadStripe(STRIPE_KEY);


const MainApp = () =>
<Elements stripe={stripePromise}>
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/" component={Root}/>
      </Switch>
    </ConnectedRouter>
  </Provider>
  </Elements>;

export default MainApp;
