import './tracks.css';
import React, { Fragment,useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardFooter, Media, Button, Form, FormGroup, Input, Pagination, PaginationItem, PaginationLink,CardBody,Label } from 'reactstrap'

import {DATE_TIME_FORMAT, Follower,Following,TotalPost} from '../../constant'
import axios from 'axios'
import { DELETE_TRACK, STORED_TRANSLATIONS } from '../../redux/actionTypes';
import { Loader } from 'react-bootstrap-typeahead';
import Moment from 'react-moment';
import * as General from '../../util/General'
import { addTrack, deleteTrack, editTrack, getTracks } from '../../redux/tracks/action';
import { toast } from 'react-toastify';
import { getTrainings } from '../../redux/trainings/action';
import AddTrack from './addTrack';
import NoAccess from '../../components/reuse/noaccess';
import SweetAlert from 'sweetalert2'


const TracksList = (props) => {
  
  const userLocale = General.getLocale();
  const dispatch = useDispatch();

  const userInfo = useSelector(({auth}) => auth.authUser);
  const tracksData = useSelector(({tracks}) => tracks.tracksData);
  const trainingsData = useSelector(({trainings}) => trainings.trainingsData);
  const addTracksRreponse = useSelector(({tracks}) => tracks.addTracksData);

  const trans = useSelector(({translation}) => translation.translationsData);
  const userIsAdmin = useSelector(({auth}) => auth.userIsAdmin);

  //SEARCH
  const [searchText, setSearchText] = useState('');
  var searchKeyword = "";

  //DATA
  const [curPage, setCurPage] = useState(0);
  const [email, setEmail] = useState(userInfo != null ? userInfo.email : "");
  const [descr, setDescr] = useState("");


  const addTrackData = useSelector(({tracks}) => tracks.addTrackData);
  const editTrackData = useSelector(({tracks}) => tracks.editTrackData);
  const deleteTrackData = useSelector(({tracks}) => tracks.deleteTrackData);
  const [addEditTrackModal, setAddEditTrackModal] = useState(false);
  const [trackToEdit, setTrackToEdit] = useState(null);
  const [trackIsEdit, setTrackIsEdit] = useState(false);

  useEffect(() => {
    if (addTrackData && addTrackData.status){
        getTracksData();
        }
    }, [addTrackData]);

    useEffect(() => {
        if (editTrackData && editTrackData.status){
            getTracksData();
        }
    }, [editTrackData]);


    useEffect(() => {
      if (deleteTrackData != null){
        if (deleteTrackData.status){
          toast.success(deleteTrackData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
            getTracksData();
        } else {
          toast.error(deleteTrackData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
        }
    
        dispatch({type: DELETE_TRACK, payload: null});
      }
    }, [deleteTrackData]);


    useEffect(() => {
        getTracksData();
    }, []);

  useEffect(() => {
    getTracksData();
    getTrainingssData();
}, []);

 
  const getTracksData = () => {
    let postData = {page: curPage+1,sort: {"_id": -1},query: {},searchKeyword: searchKeyword}
    dispatch(getTracks(postData));
  }

  const getTrainingssData = () => {
    let postData = {page: curPage+1,sort: {"_id": 1},query: {},searchKeyword: searchKeyword}
    dispatch(getTrainings(postData));
  }


  const addEditTracksModaltoggle = () => {

  }

  const handleSearchKeyword = async (keyword)   => {
    searchKeyword = keyword;
    setSearchText(keyword);
    getTracksData();
  }

  const handleTracksEditClick = (e,user) => {
    e.preventDefault();
    // history.push(`/app/users/userEdit/${user.nickname}`);
  }

  const removeTracks = (user)=> {

  }

  const handleFeedBackAnswer = (e,item) => {
    e.preventDefault();
    if ( item != null){
      window.location = `mailto:${item.email}`
    }
  }

  const handleUserClick = (e,item) => {
    e.preventDefault();
    setTrackToEdit(item);
    setTrackIsEdit(true);
    setAddEditTrackModal(true);
  }


  const askToDeleteTrack = (e,item) => {
    e.preventDefault();
    SweetAlert.fire({
      title:trans.delete_dialog_title,
      text:trans.delete_dialog_desc,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText:trans.ok_btn,
      cancelButtonText: trans.cancel_btn,
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
          //OK
            //REMOVE
          let postData = {trackId: item.id}
          dispatch(deleteTrack(postData));
      }
      else {
        
      }
    })
  }
 
  const getTracksForTraining = (training) => {
    var tracks = [];

    tracksData.tracks.map((item,i) => {
    if (item.training.id == training.id){
      
      var privateText = item.access_type == 1 ? trans.value_yes : trans.value_no;
      var active = item.active ? trans.value_status_true : trans.value_status_false; 

      tracks.push(<tr  key={`tracks_item_${i}`} className="_table_tracks_item">
                    <td>{item.title}</td>
                    {/* <td>{privateText}</td> */}
                    <td>{active}</td>
                    {/* <td><Moment locale={userLocale} format={DATE_TIME_FORMAT}>{item.publish_time}</Moment></td> */}
                    <td className="text-right">
                            <Button style={{marginRight:'5px'}} onClick={(e) => {askToDeleteTrack(e,item)}} color="secondary" size="sm"><i className="fa fa-trash"></i> {trans.delete_btn}</Button>
                             
                            <Button onClick={(e) => {handleUserClick(e,item)}} color="primary" size="sm"><i className="fa fa-pencil"></i> {trans.edit_btn}</Button>
                    </td>
                  </tr>)
      }
    });

    if (tracks.length > 0){
      return (<div className="table-responsive">
      <table className="table card-table table-vcenter text-nowrap">
          <thead>
            <tr className="_table_tracks_item">
              <th key={"title"}>{trans.track_name}</th>
              {/* <th key={"access_type"}>{trans.training_is_private}</th> */}
              <th key={"active"}>{trans.value_status}</th>
              {/* <th key={"publish_time"}>{trans.track_publish_date}</th> */}
              <th key={"track_edit"}></th>
            </tr>
          </thead>
          <tbody>
            {tracks}
          </tbody>
        </table>
      </div>);
    } else {
      return (<div className="m-10">
          {trans.no_training_tracks}
        </div>)
    }

  }

  const addEditModaltoggle = (e) => {
    setTrackToEdit(null); 
    setTrackIsEdit(false); 
    setAddEditTrackModal(!addEditTrackModal)
  }

  const onSaveTrack = (data) => {
    if (trackIsEdit){
      dispatch(editTrack(data));
    } else {
      dispatch(addTrack(data));
    }
  }

  return (
    <Fragment>
      <Breadcrumb parent={trans.tracks_title} title={trans.tracks_title} />
      <Container fluid={true}>



      {!userIsAdmin ?
       <Col md="12">
       <NoAccess/>
       </Col>
       : 

      <Row>
      <Col md="12">
              <Card>
                <CardHeader >
                  <Row>
                  <Col xl="12" sm="12"> 
                    <div style={{alignItems: 'center',}}> 
                        <h4 className="card-title mb-0 font-weight-bold" style={{float: "left"}}>{trans.tracks_title}</h4>
                        <div className="card-title mb-0" style={{float: "right"}}>
                            <Button onClick={addEditModaltoggle} color="primary" size="sm"><i className="fa fa-pencil"></i> {trans.add_btn}</Button>
                        </div>
                    </div>
                  </Col>
                    </Row>
                </CardHeader>
                </Card>
                </Col>

      {userIsAdmin && tracksData != null && trainingsData != null ? 
       trainingsData.trainings.map((training,indexTraining) => {
           return (<Col key={`training_track_${training.id}`} md="12">
              <Card>
                <CardHeader >
                  <Row>
                  <Col xl="12" sm="12"> 
                    <div style={{alignItems: 'center',}}> 
                     <h4 className="card-title mb-0 font-weight-bold" style={{float: "left"}}>{training.title}</h4>
                        
                    </div>
                  </Col>
                  <br/>
                  <br/>
                  
                    </Row>
                </CardHeader>
                  {getTracksForTraining(training)}
              </Card>
            </Col>) })
             : <Loader/>}
          </Row>}
      </Container>
      <AddTrack
        open={addEditTrackModal}
        onAddTrackClose={addEditModaltoggle}
        onSaveTrack={(data) => onSaveTrack(data)}
        isEditTrack={trackIsEdit}
        trackToEdit={trackToEdit}
        trainings={trainingsData != null ? trainingsData.trainings : null}
      />
    </Fragment>
  );
}

export default TracksList;