import { initializeApp, getApps } from "firebase/app";
import { getAnalytics, logEvent as firebaseLogEvent } from "firebase/analytics";
import { getMessaging, getToken } from "firebase/messaging";
import Api from "../util/Api";
import * as Const from "../constant";

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDnOXIkDjs3Z9-JqME4fxpIQOgVR6XsJM8",
  authDomain: "atplab-app.firebaseapp.com",
  databaseURL: "https://atplab-app-default-rtdb.firebaseio.com",
  projectId: "atplab-app",
  storageBucket: "atplab-app.appspot.com",
  messagingSenderId: "456349061441",
  appId: "1:456349061441:web:e281773007397a06e491c0",
  measurementId: "G-VZ2Z2YCH2L",
};

// Initialize Firebase App instance
let firebaseApp;
if (!getApps().length) {
  firebaseApp = initializeApp(firebaseConfig);
} else {
  firebaseApp = getApps()[0];
}

// Initialize Analytics
let analytics;
if (typeof window !== "undefined" && "measurementId" in firebaseConfig) {
  analytics = getAnalytics(firebaseApp);
  console.log("Firebase Analytics initialized.");
}

export const initializeFirebase = () => {
  console.log("Firebase already initialized.");
  return firebaseApp;
};

// Request permission to receive notifications
export const askForPermissioToReceiveNotifications = async () => {
  try {
    const messaging = getMessaging(firebaseApp);

    // Request permission to display notifications
    await Notification.requestPermission();
    const token = await getToken(messaging, { vapidKey: "YOUR_VAPID_KEY" });

    if (!token) {
      console.error("Failed to get token for notifications.");
      return null;
    }

    console.log("Notification token:", token);
    return token;
  } catch (error) {
    console.error("Error getting permission for notifications:", error);
  }
};

// Subscribe to a topic
export const subscribeToTopic = async (topic) => {
  try {
    const messaging = getMessaging(firebaseApp);

    const token = await getToken(messaging, { vapidKey: "YOUR_VAPID_KEY" });
    const postData = { topic, token };

    const response = await Api.post("/settings/subscribeToTopic", postData);

    if (Const.DEBUG_MODE) {
      console.log("Subscribed to topic:", response);
    }
  } catch (error) {
    if (Const.DEBUG_MODE) {
      console.error("Error subscribing to topic:", error);
    }
  }
};

// Unsubscribe from a topic
export const unsubscribeToTopic = async (topic) => {
  try {
    const messaging = getMessaging(firebaseApp);

    const token = await getToken(messaging, { vapidKey: "YOUR_VAPID_KEY" });
    const postData = { topic, token };

    const response = await Api.post("/settings/unsubscribeToTopic", postData);

    if (Const.DEBUG_MODE) {
      console.log("Unsubscribed from topic:", response);
    }
  } catch (error) {
    if (Const.DEBUG_MODE) {
      console.error("Error unsubscribing from topic:", error);
    }
  }
};

// Log custom events to Firebase Analytics
export const logEvent = async (eventName, eventData) => {
  try {
    if (analytics) {
      firebaseLogEvent(analytics, eventName, eventData);

      if (Const.DEBUG_MODE) {
        console.log(`Logged event: ${eventName}`, eventData);
      }
    }
  } catch (error) {
    console.error("Error logging event:", error);
  }
};
