import './workouts.css';
import React, { Fragment,useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardFooter, Media, Button, Form, FormGroup, Input, Pagination, PaginationItem, PaginationLink,CardBody,Label } from 'reactstrap'

import {DATE_TIME_FORMAT, Follower,Following,TotalPost} from '../../constant'
import axios from 'axios'
import { FETCH_WORKOUTS, STORED_TRANSLATIONS } from '../../redux/actionTypes';
import { Loader } from 'react-bootstrap-typeahead';
import Moment from 'react-moment';
import * as General from '../../util/General'
import { editWorkout, getWorkouts, singleWorkout } from '../../redux/workouts/action';
import { toast } from 'react-toastify';
import AddWorkout from './addWorkout';
import { useParams } from 'react-router';
import NoAccess from '../../components/reuse/noaccess';

const WorkoutsSingle = (props) => {

  //LETS GRAB THE PASSED ID
  var workoutId = "";
  let { id } = useParams();
  if (id != null){
    workoutId = id;
  }
  
  const userLocale = General.getLocale();
  const dispatch = useDispatch();

  const userInfo = useSelector(({auth}) => auth.authUser);
  const singleWorkoutData = useSelector(({workouts}) => workouts.singleWorkoutData);
  const addWorkoutsRreponse = useSelector(({workouts}) => workouts.addWorkoutsData);

  const trans = useSelector(({translation}) => translation.translationsData);
  const userIsAdmin = useSelector(({auth}) => auth.userIsAdmin);


  useEffect(() => {
    getWorkoutData();
  }, []);


  // useEffect(() => {
  //   if (singleWorkoutData != null){
  //     dispatch({type: FETCH_WORKOUTS, payload: null});
  //   }
  // }, [singleWorkoutData]);
 
  const getWorkoutData = () => {
    let postData = {workoutId: workoutId}
    dispatch(singleWorkout(postData));
}


  const addEditWorkoutsModaltoggle = () => {

  }

  const handleFeedBackAnswer = (e,item) => {
    e.preventDefault();
    if ( item != null){
      window.location = `mailto:${item.email}`
    }
  }

  const onWorkoutStored = () => {
    getWorkoutData();
  }

  return (
    <Fragment>
      <Breadcrumb parent={trans.workouts_title} title={trans.workouts_title} />
      <Container fluid={true}>

      {!userIsAdmin ?
       <Col md="12">
       <NoAccess/>
       </Col>
       : 

      <Row>
      
        {singleWorkoutData != null ? 
        <Col md="12">
          <AddWorkout
            onWorkoutStored={onWorkoutStored}
            isEdit={true}
            singleWorkoutItem={singleWorkoutData}
            fromModal={false}
            fromEditSchedule={false}
            fromSchedule={false}
          />
        </Col>
        : <div></div>}

      
            </Row>
      }
      </Container>
    </Fragment>
  );
}

export default WorkoutsSingle;