import React,{useState,useEffect} from 'react';
import man from '../assets/images/dashboard/profile.jpg';
import {Container,Row,Col,Form,FormGroup,Input,Label,Button,NavItem, NavLink, Nav,TabContent,TabPane} from 'reactstrap'
import { handleResponse } from '../services/fack.backend'
import { useAuth0 } from '@auth0/auth0-react'
import { toast } from 'react-toastify';
import {withRouter} from 'react-router-dom'
import {Password,SignIn, EmailAddress,RememberPassword,ForgotPassword, ARG_USER_ROLE_USER} from '../constant';
import {userSignIn,getUser, userSignUp} from '../redux/auth/action';
import {useDispatch, useSelector} from 'react-redux';
import { CREATE_USER_SUCCESS, USER_TOKEN_SET } from '../redux/actionTypes';
import * as General from '../util/General'

const SignUp = (props) => {

  const userLocale = General.getLocale();
  const dispatch = useDispatch();
  
    const {loginWithRedirect} = useAuth0()
   
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [nickname, setNickname] = useState("");
    const [termsConfirmed, setTermsConfirmed] = useState(false);
    
    const [loading,setLoading] = useState(false) 
    const [selected, setSelected] = useState("jwt");
    const [togglePassword,setTogglePassword] = useState(false);
    const {login,signUp}= useSelector(({auth}) => auth);
    const message = useSelector(({auth}) => auth.message);

    const token =  JSON.parse(localStorage.getItem(USER_TOKEN_SET));
    const trans = useSelector(({translation}) => translation.translationsData);
    const settingsData = useSelector(({settings}) => settings.settingsData);



    


    useEffect(() => {
      if (login != null){
        if (login.status){
          if (token != null){
            setLoading(false);
            window.location.reload();
          }
        }
      }
    }, [login]);

    useEffect(() => {
      if (signUp != null){
        if (signUp.status){
            loginWithJwt();
        } else {
          setLoading(false);
          toast.error(signUp.message, {
            position: toast.POSITION.TOP_RIGHT
            });
        }
        dispatch({type: CREATE_USER_SUCCESS, payload: null});
      }
    }, [signUp]);

    useEffect(() => {
          if (message != null){
            toast.error(message, {
              position: toast.POSITION.TOP_RIGHT
              });
          }
    }, [message]);

    
    const loginWithJwt = () => {
      dispatch(userSignIn(email, password));
    }

    const registerAccount = (e) => {
      e.preventDefault();

      if (!termsConfirmed) {
        toast.error(trans.accept_terms, {
          position: toast.POSITION.TOP_RIGHT
          });
          return;
      }

      var userRoles = [];
      settingsData.userRoles.forEach((element) => {
        if (element.role_id == ARG_USER_ROLE_USER){
          userRoles.push(element._id);
        }
      });

      let postData = {
        email,
        password: password,
        firstName,
        lastName,
        nickname,
        userRoles: userRoles
      }
      setLoading(true);
      dispatch(userSignUp(postData))
    }

    return (
      <Container fluid={true} className="p-0">
      <Row>
        <Col xs="12">     
          <div className="login-card">
            <div>
               <div>
                  <a className="logo" href="index.html">
                    <img className="img-fluid for-light" src={require("../assets/images/logo/login.png")} alt=""/>
                    <img className="img-fluid for-dark" src={require("../assets/images/logo/logo_dark.png")} alt=""/>
                  </a>
                </div>
              <div className="login-main"> 
                <Form className="theme-form">
                  <h4>{trans.register_title}</h4>
                  <p>{trans.register_descr}</p>
                  <FormGroup>
                    <Label className="col-form-label pt-0">{trans.fullname_field}</Label>
                    <div className="form-row">
                      <Col xs="6">
                        <Input value={firstName} onChange={e => setFirstName(e.target.value)} className="form-control" type="text" required  placeholder={trans.first_name_field}/>
                      </Col>
                      <Col xs="6">
                        <Input value={lastName} onChange={e => setLastName(e.target.value)}  className="form-control" type="text" required="" placeholder={trans.last_name_field}/>
                      </Col>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">{trans.email_field}</Label>
                    <Input  autoComplete="new-password" value={email} onChange={e => setEmail(e.target.value)} className="form-control" type="email" required="" placeholder={trans.email_field}/>
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">{trans.nickname_field}</Label>
                    <Input autoComplete="new-password" value={nickname} onChange={e => setNickname(e.target.value)} className="form-control" type="nickname" required="" placeholder={trans.nickname_field}/>
                  </FormGroup>
                  <FormGroup>
                        <Label className="col-form-label">{trans.pass_field}</Label>
                        <Input autoComplete="new-password" className="form-control" type={togglePassword ?  "text" : "password"} onChange={e => setPassword(e.target.value)} defaultValue={password} required="" placeholder={trans.pass_field}/>
                        <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}><span >{togglePassword ? trans.pass_hide : trans.pass_view}</span></div>
                  </FormGroup>
                  <div className="form-group mb-0">
                    <div className="checkbox ml-3">
                      <Input value={termsConfirmed} onChange={e => setTermsConfirmed(e.target.value)} id="checkbox1" type="checkbox"/>
                      <Label className="text-muted" for="checkbox1">{trans.agree_to_all}<a className="ml-2" target="_blank" href={`/terms/${userLocale}`}>{trans.terms_of_use}</a></Label>
                    </div>
                    <Button disabled={loading} onClick={(e) => registerAccount(e)} color="primary" className="btn-block" type="submit">
                     {loading ? <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span> : ""}
                      {trans.create_account_btn}
                      </Button>
                  </div>
                  <p className="mt-4 mb-0">{trans.already_member}<a className="ml-2" href="/login">{trans.sign_in_btn}</a></p>
                </Form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      </Container>
    );
}

export default SignUp;