import "./trainings.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { getClientToken, getSubPlan } from "../../redux/subscription/action";
import LoaderBox from "../../components/reuse/loaderbox";
import { PAYMENT_TYPE_STRIPE } from "../../constant";
import axios from "../../util/Api";
import { FETCH_CLIENT_TOKEN, GET_SUBSCRIPTION } from "../../redux/actionTypes";

const GetTrainingModal = (props) => {
  const { getTrainingItem, onGetTrainingModalClose, open } = props;

  const dispatch = useDispatch();
  const settingsData = useSelector(({ settings }) => settings.settingsData);
  const trans = useSelector(({ translation }) => translation.translationsData);
  const clientTokenData = useSelector(
    ({ subscription }) => subscription.clientTokenData
  );
  const getSubData = useSelector(({ subscription }) => subscription.getSubData);

  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const [isMonthly, setIsMonthly] = useState(true);
  const [clientTokenMessage, setClientTokenMessage] = useState("");
  const [clientTokenHasError, setClientTokenHasError] = useState(false);
  const [stripeCusID, setStripeCusID] = useState("");
  const [setupIntentClientSecret, setSetupIntentClientSecret] = useState("");

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (open) {
      dispatch(getClientToken({}));
    }
  }, [open, dispatch]);

  useEffect(() => {
    if (clientTokenData) {
      const { status, message, stripeCusID, subSystem } = clientTokenData;

      setClientTokenMessage(message);
      setClientTokenHasError(status === false && message !== "");
      setStripeCusID(stripeCusID);

      if (subSystem === PAYMENT_TYPE_STRIPE) {
        fetchPaymentSheet(stripeCusID);
      }

      dispatch({ type: FETCH_CLIENT_TOKEN, payload: null });
    }
  }, [clientTokenData, dispatch]);

  useEffect(() => {
    if (getSubData) {
      setIsPaymentLoading(false);
      if (getSubData.status) {
        toast.success(getSubData.message);
        onGetTrainingModalClose();
      } else {
        toast.error(getSubData.message);
      }

      dispatch({ type: GET_SUBSCRIPTION, payload: null });
    }
  }, [getSubData, dispatch]);

  const fetchPaymentSheet = async (customerID) => {
    try {
      const planId = isMonthly
        ? getTrainingItem.plan_id
        : `${getTrainingItem.plan_id}_year`;
      const response = await axios.post("subscription/payment-sheet", {
        customerId: customerID,
        planId,
      });

      if (response.data?.setupIntent) {
        setSetupIntentClientSecret(response.data.setupIntent);
      } else {
        toast.error("Failed to retrieve setup intent.");
      }
    } catch (error) {
      console.error("Error fetching payment sheet:", error);
      toast.error("Error retrieving setup intent.");
    }
  };

  const handleSubscription = async () => {
    if (!stripe || !elements) {
      toast.error("Stripe is not initialized.");
      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      toast.error("CardElement is not mounted.");
      return;
    }

    setIsPaymentLoading(true);

    try {
   
      const {setupIntent, error } = await stripe.confirmCardSetup(setupIntentClientSecret, {
        payment_method: { card: cardElement },
      });

      if (error) {
        toast.error(`Setup failed: ${error.message}`);
        setIsPaymentLoading(false);
        return;
      }

      finalizeSubscription(setupIntent);
    } catch (error) {
      console.error("Error during setup:", error);
      toast.error("An error occurred during the setup process.");
      setIsPaymentLoading(false);
    }
  };

  const finalizeSubscription = (setupIntent) => {
    const planId = isMonthly
      ? getTrainingItem.plan_id
      : `${getTrainingItem.plan_id}_year`;

    const postData = {
      stripeCusID,
      setupIntent: setupIntent.id,
      planId,
    };

    dispatch(getSubPlan(postData));
  };

  const selectPerType = (e, typeBool) => {
    e.preventDefault();
    setIsMonthly(typeBool);
    setClientTokenMessage(null);
    dispatch(getClientToken({}));
  };

  return (
    <Modal isOpen={open} toggle={onGetTrainingModalClose} centered size="lg">
      <ModalHeader toggle={onGetTrainingModalClose}>
        <span className="font-weight-bold">{trans.get_training}</span>
      </ModalHeader>
    
        <ModalBody>
        
            <Row>
              <Col sm="6" md="6">
                <Button
                  onClick={(e) => {
                    selectPerType(e, true);
                  }}
                  className={`_btn_training_pick_type_per ${
                    isMonthly ? "_btn_training_pick_type_per_selected" : ""
                  }`}
                >
                  {trans.per_month}
                </Button>
              </Col>
              <Col sm="6" md="6">
                <Button
                  onClick={(e) => {
                    selectPerType(e, false);
                  }}
                  className={`_btn_training_pick_type_per ${
                    !isMonthly ? "_btn_training_pick_type_per_selected" : ""
                  }`}
                >
                  {trans.per_year}
                </Button>
              </Col>
              {getTrainingItem != null ?   <Col sm="12" md="12">
             <div className="_traning_info_container_modal">
                  <div
                    style={{ display: "flex", margin: "0px", padding: "0px" }}
                  >
                    <p
                      style={{ display: "flex", margin: "0px", padding: "0px" }}
                    >
                      {trans.training_name}:{" "}
                    </p>
                    <p className="font-weight-bold _training_info_text">{` ${getTrainingItem.title}`}</p>
                  </div>
                  <div
                    style={{ display: "flex", margin: "0px", padding: "0px" }}
                  >
                    <p
                      style={{ display: "flex", margin: "0px", padding: "0px" }}
                    >
                      {trans.value_price}:{" "}
                    </p>
                    <p
                      style={{ color: "red" }}
                      className="font-weight-bold _training_info_text"
                    >
                      {` ${
                        isMonthly
                          ? getTrainingItem.price
                          : getTrainingItem.price_year
                      }`}
                      €
                    </p>
                  </div>

                  <div
                    style={{ display: "flex", margin: "0px", padding: "0px" }}
                  >
                    <p
                      style={{ display: "flex", margin: "0px", padding: "0px" }}
                    >
                      {trans.training_desc}:{" "}
                    </p>
                    <p className="font-weight-bold _training_info_text">{` ${getTrainingItem.desc}`}</p>
                  </div>
                </div>

                <div className="_traning_info_container_modal">
                  <p className="font-weight-bold">
                    {trans.subscribe_description}
                  </p>
                </div> 
              </Col> : null}

              {settingsData.settings.subSystem == PAYMENT_TYPE_STRIPE ? (
                <Col sm="12" md="12">
                  <div className="_stripe_payment_bg">
                    <h6>{trans.sub_add_card_details}</h6>
                    <br />
                    <CardElement />
                    <br />
                  </div>
                </Col>
              ) : null}
            </Row>
          
        </ModalBody>
      
      <ModalFooter>
        {isPaymentLoading == true ?  <div className="loader-box" >
                                    <div className="loader-9"></div> </div>
                                   : 
        <Button
          color="primary"
          disabled={
            isPaymentLoading ||
            (stripeCusID == "")
          }
          onClick={() => {
            handleSubscription();
          }}
        >
          {trans.submit_btn}
        </Button> }
      </ModalFooter>
    </Modal>
  );
};

export default GetTrainingModal;
