import React, { Fragment, useState, useEffect } from 'react';
import { Type, Home, Airplay, Box, FolderPlus, Command, Cloud, FileText, Server, BarChart, Users, Layers, ShoppingBag, Aperture, Mail, MessageCircle, GitPullRequest, Monitor, Heart, Clock, Zap, CheckSquare, Calendar, Image, Film, HelpCircle, Radio, Map, Edit, Sunrise, Package, Triangle, Database, User, Volume, Anchor, Bell, Rss, Sliders } from 'react-feather';
import { useSelector } from 'react-redux';
import { ArrowRight, ArrowLeft, Grid } from 'react-feather';
import { Link, useLocation } from 'react-router-dom';
import { translate } from 'react-switch-lang';
import configDB from '../../data/customizer/config';

const Sidebar = (props) => {
  const [margin, setMargin] = useState(0);
  const [width, setWidth] = useState(0);
  const [sidebartoogle, setSidebartoogle] = useState(true);
  const wrapper = useSelector(content => content.Customizer.sidebar_types.type) || configDB.data.settings.sidebar.type;
  const authUserData = useSelector(({ auth }) => auth.authUser);
  const trans = useSelector(({ translation }) => translation.translationsData);
  const userIsAdmin = useSelector(({ auth }) => auth.userIsAdmin);
  const userIsManager = useSelector(({ auth }) => auth.userIsManager);
  const userIsCoach = useSelector(({ auth }) => auth.userIsCoach);
  const location = useLocation();

  var MENU_ITEMS_DATA = [

    {
        menutitle:trans.menu_general,
        menucontent:trans.menu_general_desc,
        Items:[
             { path: `${process.env.PUBLIC_URL}/dashboard/default`,icon:Home, title: trans.menu_dashboard, type: 'link',active: false , slug: "dashboard"}
        ]
    },

    {
        menutitle:trans.menu_application,
        menucontent:trans.menu_application_desc,
        Items:[
            { path: `${process.env.PUBLIC_URL}/app/schedule`,icon:Calendar, title: trans.menu_schedule, type: 'link',active: false, slug: "schedule" },
            { path: `${process.env.PUBLIC_URL}/app/users/progress`,icon:Heart, title: trans.bottom_nav_progress, type: 'link',active: false, slug: "progress" },
            { path: `${process.env.PUBLIC_URL}/app/athleteslab`,icon:Anchor, title: trans.bottom_nav_athletes_lab, type: 'link',active: false, slug: "athleteslab" },
            { path: `${process.env.PUBLIC_URL}/app/nutrition`,icon: Aperture, title: trans.bottom_nav_nutrition, type: 'link',active: false, slug: "nutrition" },   
            { path: `${process.env.PUBLIC_URL}/app/users/userProfile/${authUserData.nickname}`,icon:User, title: trans.bottom_nav_profile, type: 'link',active: false, slug: "profile" },
            { path: `${process.env.PUBLIC_URL}/app/users/coaches`,icon:CheckSquare, title: trans.menu_coaches, type: 'link',active: false, slug: "coaches" },
            { path: `${process.env.PUBLIC_URL}/app/users/userList`,icon:Users, title: trans.menu_users, type: 'link',active: false, slug: "users" },
            { path: `${process.env.PUBLIC_URL}/app/workouts`,icon:Database, title: trans.menu_workouts, type: 'link',active: false, slug: "workouts" },
            { path: `${process.env.PUBLIC_URL}/app/trainings`,icon:Layers, title: trans.menu_trainings, type: 'link',active: false, slug: "trainings" },
            { path: `${process.env.PUBLIC_URL}/app/tracks`,icon:Triangle, title: trans.menu_tracks, type: 'link',active: false, slug: "tracks" }, 
            
            { path: `${process.env.PUBLIC_URL}/app/scoretypes`,icon:Type, title: trans.menu_score_types, type: 'link',active: false, slug: "scoretypes" },
            { path: `${process.env.PUBLIC_URL}/app/send-notification`,icon:Bell, title: 'Push Notifications', type: 'link',active: false, slug: "send-notification" },
            { path: `${process.env.PUBLIC_URL}/app/blog-list`,icon:Rss, title: trans.blog_list_title, type: 'link',active: false, slug: "blog-list" }
        ]
    },

    {
        menutitle:trans.menu_settings,
        menucontent:trans.menu_settings_desc,
        Items:[
            { path: `${process.env.PUBLIC_URL}/feedback`,icon:Mail, title: trans.feedback_title, type: 'link',active: false, slug: "feedback" },
            { path: `${process.env.PUBLIC_URL}/translations`,icon:Command, title: trans.translations_title, type: 'link',active: false , slug: "translations" }
        ]
    }];

    var menuDataItems = [];

    MENU_ITEMS_DATA.map(menuItem => {

      var newMnuItem = {menutitle:menuItem.menutitle,menucontent:menuItem.menucontent,Items:[]};

      //console.log("menuItem",menuItem);
      menuItem.Items.forEach((innerMenuItem,innerMenuItemIndex) => {
      //  console.log("innerMenuItem.slug",innerMenuItem.slug);
        

        if (innerMenuItem.slug == "translations" || innerMenuItem.slug == "users" ||
        innerMenuItem.slug == "trainings" || innerMenuItem.slug == "scoretypes"|| 
        innerMenuItem.slug == "tracks"  || innerMenuItem.slug == "users"|| 
        innerMenuItem.slug == "workouts" || innerMenuItem.slug == "schedule" || innerMenuItem.slug == "send-notification"  || innerMenuItem.slug ==  "blog-list" ){
          var hasAcess = false;

          if (innerMenuItem.slug == "translations" || 
          innerMenuItem.slug == "trainings"  || 
          innerMenuItem.slug == "tracks"   ){
            if (userIsAdmin){
              hasAcess = true;
            }
          } else {
            if (userIsManager || userIsAdmin){
              hasAcess = true;
            }
          }

          if ( innerMenuItem.slug == "users"|| 
          innerMenuItem.slug == "workouts" || innerMenuItem.slug == "schedule" || innerMenuItem.slug == "send-notification" || innerMenuItem.slug ==  "blog-list" ) {
            if (userIsCoach){
              hasAcess = true;
            }
          }
        

          if (hasAcess){
            newMnuItem.Items.push(innerMenuItem);
          }
        } else {
          newMnuItem.Items.push(innerMenuItem)
        }
      });
      if ( newMnuItem.Items.length > 0){
         menuDataItems.push(newMnuItem);
      }
    }); 

    const [mainmenu, setMainMenu] = useState(menuDataItems);
  
    // Synchronize active state with URL
    useEffect(() => {
      const currentUrl = location.pathname;
  
      const updatedMenu = mainmenu.map(menuItem => {
        menuItem.Items.forEach(item => {
          item.active = item.path === currentUrl;
          if (item.children) {
            item.children.forEach(child => {
              child.active = child.path === currentUrl;
            });
          }
        });
        return menuItem;
      });
  
      setMainMenu(updatedMenu);
    }, [location.pathname]); // Runs on URL changes
  
    useEffect(() => {
      document.querySelector(".left-arrow").classList.add("d-none");
      window.addEventListener('resize', handleResize);
      handleResize();
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  
    const handleResize = () => {
      setWidth(window.innerWidth - 500);
    };
  
    const scrollToRight = () => {
      if (margin <= -2598 || margin <= -2034) {
        setMargin(width === 492 ? -3570 : -3464);
        document.querySelector(".right-arrow").classList.add("d-none");
        document.querySelector(".left-arrow").classList.remove("d-none");
      } else {
        setMargin(margin => margin + (-width));
        document.querySelector(".left-arrow").classList.remove("d-none");
      }
    };
  
    const scrollToLeft = () => {
      if (margin >= -width) {
        setMargin(0);
        document.querySelector(".left-arrow").classList.add("d-none");
        document.querySelector(".right-arrow").classList.remove("d-none");
      } else {
        setMargin(margin => margin + width);
        document.querySelector(".right-arrow").classList.remove("d-none");
      }
    };
  
    const toggletNavActive = (item) => {
      if (window.innerWidth <= 991) {
        document.querySelector(".page-header").className = "page-header close_icon";
        document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper close_icon ";
      }
  
      const tempMenu = mainmenu.map(menu => {
        menu.Items.forEach(subItem => {
          subItem.active = subItem === item;
          if (subItem.children) {
            subItem.children.forEach(child => (child.active = child === item));
          }
        });
        return menu;
      });
  
      setMainMenu(tempMenu);
    };
  
    return (
      <Fragment>
        <div className="sidebar-wrapper">
          <div className="logo-wrapper">
            <Link to={`${process.env.PUBLIC_URL}/dashboard/default`}>
              <img className="img-fluid for-light" src={require("../../assets/images/logo/logo.png")} alt="" />
              <img className="img-fluid for-dark" src={require("../../assets/images/logo/logo_dark.png")} alt="" />
            </Link>
            <div className="toggle-sidebar" onClick={() => setSidebartoogle(!sidebartoogle)}>
              <Grid className="status_toggle middle sidebar-toggle" />
            </div>
          </div>
          <nav className="sidebar-main">
            <div className="left-arrow" onClick={scrollToLeft}><ArrowLeft /></div>
            <div id="sidebar-menu" style={wrapper === 'horizontal-wrapper' ? { marginLeft: margin + 'px' } : { margin: '0px' }}>
              <ul className="sidebar-links custom-scrollbar">
                {mainmenu.map((menu, i) => (
                  <Fragment key={i}>
                    <li className="sidebar-main-title">
                      <div>
                        <h6>{props.t(menu.menutitle)}</h6>
                        <p>{props.t(menu.menucontent)}</p>
                      </div>
                    </li>
                    {menu.Items.map((item, index) => (
                      <li className="sidebar-list" key={index}>
                        <Link
                          to={item.path}
                          className={`sidebar-link ${item.active ? 'active' : ''}`}
                          onClick={() => toggletNavActive(item)}
                        >
                          <item.icon />
                          <span>{props.t(item.title)}</span>
                        </Link>
                      </li>
                    ))}
                  </Fragment>
                ))}
              </ul>
            </div>
            <div className="right-arrow" onClick={scrollToRight}><ArrowRight /></div>
          </nav>
        </div>
      </Fragment>
    );
  };
  
  export default translate(Sidebar);