import  './schedule.css';
import React,{useEffect, useState} from 'react';
import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Form, FormGroup, Input, Label, Button,NavItem, NavLink, Nav,TabContent,TabPane, Breadcrumb, Card, CardHeader, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import WorkoutLeaderboard from './workoutLeaderboard';
import {DATE_TIME_FORMAT, SCORE_TYPE_CALORIES, SCORE_TYPE_CHECKBOX, SCORE_TYPE_KGS_LBS, SCORE_TYPE_METERS, SCORE_TYPE_REPS, SCORE_TYPE_TIME } from '../../constant';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import * as General from '../../util/General'
import { CHAT_CREATE_ROOM, USER_ID_SET } from '../../redux/actionTypes';

import { createChatRoom } from '../../redux/chat/action';
import ScheduleWorkout from './scheduleWorkout';
import DoneSchedule from './doneSchedule';
import WorkoutLeaderboardModal from './workoutLeaderboardModal';
import WorkoutNotesModal from './workoutNotesModal';
import ChatModal from '../chat/chatModal';


const WorkoutLeaderboardDetailsModal = (props) => {
    
    const userLocale = General.getLocale();
    const dispatch = useDispatch();

    const {onWorkoutLeaderboardDetailsModalClose, open, workoutBoardData,scheduleWorkout,woroutBoardIndex} = props;
    const trans = useSelector(({translation}) => translation.translationsData);

    const userId = JSON.parse(localStorage.getItem(USER_ID_SET));
    const userIsAdmin = useSelector(({auth}) => auth.userIsAdmin);  
    const userIsManager = useSelector(({auth}) => auth.userIsManager);  
    const userIsCoach = useSelector(({auth}) => auth.userIsCoach);  

    const userInfo = useSelector(({ auth }) => auth.authUser);
    const createChatRoomData = useSelector(({chat}) => chat.createChatRoomData);  

    const [workoutLeaderboardItem,setWorkoutLeaderboardItem] = useState(null);
    const [workoutLeaderboardOpen,setWorkoutLeaderboardOpen] = useState(false);
    const [workoutNotesModalOpen,setWorkoutNotesModalOpen] = useState(false);
    const [workoutNotesContentModal,setWorkoutNotesContentModal] = useState("");
    const [workoutNotesTitleModal,setWorkoutNotesTitleModal] = useState("");
    const [doneScheduleEdit,setDoneScheduleEdit] = useState(false);
    const [doneScheduleToggle,setDoneScheduleToggle] = useState(false);
    const [doneScheduleWorkout,setDoneScheduleWorkout] = useState(null);

    
    const [selectedChatUserId,setSelectedChatUserId] = useState(null);
  
  
    const [chatModalStatus,setChatModalStatus] = useState(false);




  const openChatWith = (e) => {
    e.preventDefault();
    setSelectedChatUserId(workoutBoardData.creator.id)
    setChatModalStatus(true);
  }

  const openLeaderBoards = (e,event) => {
    e.preventDefault();
    //OPEN TO SEE THE WORKOUT LEADERBOARD
    setWorkoutLeaderboardItem(event);
    setWorkoutLeaderboardOpen(true);
  }

  const openNotes = (e,notes,title) => {
    e.preventDefault();
  //  console.log(notes);
    setWorkoutNotesContentModal(notes);
    setWorkoutNotesModalOpen(true);
    setWorkoutNotesTitleModal(title);
  }
  
  const openDoneSchedule= (item) => {
    // console.log(item);
     setDoneScheduleEdit(false);
     setDoneScheduleWorkout(item)
     setDoneScheduleToggle(true);
   }
 
   const openEditDoneSchedule = (item) => {
     setDoneScheduleEdit(true);
     setDoneScheduleWorkout(item);
     setDoneScheduleToggle(true);
   }

   const onWorkoutLeaderboardModalClose = () => {
    setWorkoutLeaderboardItem(null)
    setWorkoutLeaderboardOpen(false);
  }

  const onWorkoutNotesModalClose = (e) => {
    e.preventDefault();
    setWorkoutNotesContentModal("");
    setWorkoutNotesModalOpen(false);
    setWorkoutNotesTitleModal("");
  }

  function pad(num, size) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
}

    function renderWorkoutLeader() {
        console.log("scheduleWorkout",workoutBoardData);

    

        let item = workoutBoardData;
        let index =woroutBoardIndex;

        var finishedSetsText = "";
        let toProfile = `/app/users/userProfile/${item.creator.nickname}`;
    
      
    
        let checkBoxValue = ""
        let timeValue = ""
        let caloriesValue = ""
        let repsValue = ""
        let metersValue = ""
        let kgsValue = ""
    
        var doneScheduleWorkout;
        if (doneScheduleWorkout == null){
          //WE NEED TO FIND THE SCHEDULE WORKOUT
          item.schedule.workouts.forEach(scheduleWorkout => {
            if (scheduleWorkout.workout.id == item.doneWorkout.id){
              doneScheduleWorkout = scheduleWorkout;
            }
          });
        }
       
    
    
        if (item.scoring.type == SCORE_TYPE_CHECKBOX){
          finishedSetsText = `${trans.leaderboard_from_sets_finished} ${item.doneSetsCount}/${workoutBoardData.scoring.amount}`
        } else  if (item.scoring.type == SCORE_TYPE_TIME){
          finishedSetsText = `${trans.leaderboard_time_count}: ${pad(item.bestSet.minutes,2)}:${pad(item.bestSet.seconds,2)}`
        } else  if (item.scoring.type == SCORE_TYPE_KGS_LBS){
          var type = item.bestSet.customType != null && item.bestSet.customType == "LB" ? "lbs" : "kgs"
          finishedSetsText = `${trans.leaderboard_time_count}: ${item.bestSet.value}${type}`
        } else  if (item.scoring.type == SCORE_TYPE_METERS){
          finishedSetsText = `${trans.leaderboard_time_count}: ${item.bestSet.value}m`
        } else  if (item.scoring.type == SCORE_TYPE_REPS){
          finishedSetsText = `${trans.leaderboard_time_count}: ${item.bestSet.value}reps`
        } else  if (item.scoring.type == SCORE_TYPE_CALORIES){
          finishedSetsText = `${trans.leaderboard_time_count}: ${item.bestSet.value}cal`
        } 

        //console.log(item);

        return (<div key={`workout_details_${item.id}`}>

            <ScheduleWorkout
                              event={doneScheduleWorkout}
                              index={0}
                              openLeaderBoards={openLeaderBoards}
                              openNotes={openNotes}
                              openDoneSchedule={openDoneSchedule}
                              openEditDoneSchedule={openEditDoneSchedule}
                              fromProgress={true}
                              showLeaderboard={false}
                            />
             
            <div style={{display:'flex',flexDirection: 'row', alignItems: 'center',justifyContent:'space-between'}}>
             <div style={{display:'flex',flexDirection: 'row', alignItems: 'center'}}>

            <div>
            <span style={{backgroundColor:'red',color:'white',borderRadius:'5px',padding:'3px'}}>#{index+1}</span>
            </div>
            
             <Link style={{marginLeft:'4px'}} to={toProfile}><img className="rounded-circle shadow-sm" style={{objectFit: "cover",width:40, height: 40}} src={item.creator.img_thumb} alt="40x40"  data-holder-rendered="true" /></Link>
             <div style={{marginLeft:'4px'}}>
             
              <div>
               <span >{item.creator.name}</span>
               </div>
               <div>
                 <span >@{item.creator.nickname}</span>
               </div>

             </div>
          
             </div>
             <div >
               <span className="_leaderboard_finished_text">{finishedSetsText}</span>
              </div>
           
            </div>
            
            <hr/>
       
            {/* <div style={{marginTop:'15px'}}><span className="_leaderboard_finished_text">{finishedSetsText}</span></div> */}
      
            <div >
               <b>{`${trans.done_schedule_notes}: `}</b> {item.notes != "" ?  item.notes : trans.workout_no_notes }
            </div>
           
            <hr/>
        <Row style={{marginTop:'15px'}}>
                        {item.doneSets.map((doneSetItem,doneSetIndex)=> {
                        var finishedSet = trans.leaderboard_set_finished;
                        
                        if (item.scoring.type == SCORE_TYPE_TIME){ 
                            finishedSet = `${pad(doneSetItem.minutes,2)}:${pad(doneSetItem.seconds,2)}`
                        }
                        if (item.scoring.type == SCORE_TYPE_KGS_LBS){ 
                          var type = doneSetItem.customType != null && doneSetItem.customType == "LB" ? "lbs" : "kgs"
                            finishedSet = `${doneSetItem.kgs}kgs - ${doneSetItem.lbs.toFixed(2)}lbs`
                        }
        
                        if (item.scoring.type == SCORE_TYPE_METERS){ 
                            finishedSet = `${doneSetItem.value}m`
                        }
        
                        if (item.scoring.type == SCORE_TYPE_REPS){ 
                            finishedSet = `${doneSetItem.value}reps`
                        }
        
        
                        if (item.scoring.type == SCORE_TYPE_CALORIES){ 
                            finishedSet = `${doneSetItem.value}cal`
                        }
        
        
                        return (<Col md="12">
                        <div className="_leaderboard_set_item">
                            <div>{`Set ${doneSetIndex+1}`}</div>
                            <div className="font-weight-bold">{finishedSet}</div>
                        </div> </Col>)
                        })}
                    </Row>

                    <hr/>

                    <div>
                        {userId == item.creator.id ? null : userIsAdmin || userIsManager || userIsCoach ?
                         <button onClick={(e)=> openChatWith(e)} style={{float:'right'}} className="btn btn-primary">{trans.chat_with_user}</button>
                          : null }
                        
                    </div>
        
            </div>)  
      }
      
      const onChatModalClose = () => {
        setChatModalStatus(false);
        setSelectedChatUserId("");
      }
     

    return (<Modal isOpen={open} toggle={onWorkoutLeaderboardDetailsModalClose} centered size="xl">
             <ModalHeader toggle={onWorkoutLeaderboardDetailsModalClose}>{trans.workout_details}</ModalHeader>
             <ModalBody>
                { workoutBoardData != null && scheduleWorkout != null ?
                renderWorkoutLeader() : <div></div>}
            </ModalBody>

       

      <WorkoutLeaderboardModal
        open={workoutLeaderboardOpen}
        scheduleWorkout={workoutLeaderboardItem}
        onWorkoutLeaderboardClose={onWorkoutLeaderboardModalClose}
     />     

     <WorkoutNotesModal
        open={workoutNotesModalOpen}
        notes={workoutNotesContentModal}
        title={workoutNotesTitleModal}
        onWorkoutNotesModalClose={onWorkoutNotesModalClose}
     />

      <ChatModal
            open={chatModalStatus}
            chatRoomPassedId={""}
            selectedUser={null}
            chatRoomUserId={selectedChatUserId}
            onChatModalClose={onChatModalClose}
          />

     
     </Modal>);
};

export default WorkoutLeaderboardDetailsModal;