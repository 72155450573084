import React, { Fragment, useState, useEffect, useRef } from 'react';
import Breadcrumb from '../../../layout/breadcrumb';
// import { firebase_app } from '../../../data/config';
// import { getFirestore, collection, onSnapshot } from 'firebase/firestore';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  FormGroup,
  Form,
  Button
} from 'reactstrap';
// import { createUser, deletedUser, editUser } from '../../../services/contact.service';
// import SweetAlert from 'sweetalert2';
// import ReactToPrint from 'react-to-print';
// import PrintPreview from './printpreview';
// import { useForm } from 'react-hook-form';
// import defaultuser from '../../../assets/images/user/user.png';
// import search from '../../../assets/images/search-not-found.png';
// import {
//   NewContacts,
//   AddContacts,
//   Views,
//   Name,
//   Age,
//   Mobile,
//   MobileNo,
//   EmailAddress,
//   Save,
//   Cancel,
//   NoDataFound,
//   PrintViews
// } from '../../../constant';
// import { Users } from 'react-feather';

const Newcontact = () => {
  // const [addurl, setAddurl] = useState(defaultuser);
  // const [editurl, setEditurl] = useState();
  // const [activeTab, setActiveTab] = useState('1');
  // const [users, setUsers] = useState([]);
  // const [editdata, setEditData] = useState({});
  // const [editing, setEditing] = useState(false);
  // const [selectedUser, setSelectedUser] = useState({});
  // const [printmodal, setPrintModal] = useState(false);
  // const [modal, setModal] = useState(false);
  // const { register, handleSubmit, formState: { errors } } = useForm();
  // const db = getFirestore(firebase_app); // Initialize Firestore
  // const componentRef = useRef();

  // // Fetch users from Firestore
  // useEffect(() => {
  //   const unsubscribe = onSnapshot(collection(db, 'contactApp'), (snapshot) => {
  //     const fetchedUsers = snapshot.docs.map((doc) => ({
  //       id: doc.id,
  //       ...doc.data()
  //     }));
  //     setUsers(fetchedUsers);
  //     if (fetchedUsers.length > 0) {
  //       setSelectedUser(fetchedUsers[0]);
  //     }
  //   });

  //   return () => unsubscribe();
  // }, [db]);

  // const AddContact = (data) => {
  //   if (data) {
  //     createUser(data, addurl);
  //     setModal(false);
  //   }
  // };

  // const UpdateContact = (data) => {
  //   if (data) {
  //     editUser(data, editurl, editdata.id);
  //     setEditing(false);
  //   }
  // };

  // const deleteUser = (userId) => {
  //   SweetAlert.fire({
  //     title: 'Are you sure?',
  //     text: 'Once deleted, you will not be able to recover this file!',
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonText: 'Ok',
  //     cancelButtonText: 'Cancel',
  //     reverseButtons: true
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       deletedUser(userId);
  //       SweetAlert.fire('Deleted!', 'Your file has been deleted.', 'success');
  //     } else {
  //       SweetAlert.fire('Your file is safe!');
  //     }
  //   });
  // };

  // const handleImageUpload = (event, setUrl) => {
  //   if (event.target.files.length === 0) return;
  //   const mimeType = event.target.files[0].type;
  //   if (!mimeType.startsWith('image/')) return;

  //   const reader = new FileReader();
  //   reader.onload = (e) => setUrl(e.target.result);
  //   reader.readAsDataURL(event.target.files[0]);
  // };

  return (
    <Fragment>
      <Breadcrumb parent="Apps" title="Contact" />
      <Container fluid>
        {/* <div className="email-wrap bookmark-wrap">
          <Row>
            <Col xl="3" className="box-col-6">
              <div className="email-left-aside">
                <Card>
                  <CardBody>
                    <div className="email-app-sidebar left-bookmark">
                      <Nav className="main-menu contact-options">
                        <NavItem>
                          <Button
                            className="btn-block btn-mail badge-light-primary"
                            onClick={() => setModal(true)}
                          >
                            <Users className="mr-2" />
                            {NewContacts}
                          </Button>
                          <Modal isOpen={modal} toggle={() => setModal(!modal)}>
                            <ModalHeader toggle={() => setModal(!modal)}>
                              {AddContacts}
                            </ModalHeader>
                            <ModalBody>
                              <Form
                                className="form-bookmark"
                                onSubmit={handleSubmit(AddContact)}
                              >
                                <FormGroup>
                                  <Label>{Name}</Label>
                                  <Input
                                    name="name"
                                    type="text"
                                    innerRef={register({ required: true })}
                                  />
                                  {errors.name && <span>Name is required</span>}
                                </FormGroup>
                                <Button type="submit">{Save}</Button>
                                <Button
                                  type="button"
                                  onClick={() => setModal(false)}
                                >
                                  {Cancel}
                                </Button>
                              </Form>
                            </ModalBody>
                          </Modal>
                        </NavItem>
                      </Nav>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </Col>

            
            <Col xl="9" md="12">
              <Card>
                <CardHeader>
                  <h5>{Views}</h5>
                </CardHeader>
                <CardBody>
                  {users.length > 0 ? (
                    users.map((user) => (
                      <div key={user.id}>
                        <h6>{user.name}</h6>
                        <Button onClick={() => deleteUser(user.id)}>Delete</Button>
                      </div>
                    ))
                  ) : (
                    <p>{NoDataFound}</p>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div> */}
      </Container>
    </Fragment>
  );
};

export default Newcontact;
