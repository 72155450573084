import './nutritionPlan.css';
import React, { Fragment,useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumb from '../../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardFooter, Media, Button, Form, FormGroup, Input, Pagination, PaginationItem, PaginationLink,CardBody,Label } from 'reactstrap'

import {DATE_TIME_FORMAT, Follower,Following,TotalPost} from '../../../constant'
import axios from 'axios'
import { DELETE_TRACK, STORED_TRANSLATIONS } from '../../../redux/actionTypes';
import { Loader } from 'react-bootstrap-typeahead';
import Moment from 'react-moment';
import * as General from '../../../util/General'
import {getNutritionPlans, addNutritionPlan, deleteNutritionPlan, editNutritionPlan } from '../../../redux/nutrition/nutritionPlan/action';
import { toast } from 'react-toastify';
import AddNutritionPlan from './addNutritionPlan';
import NoAccess from '../../../components/reuse/noaccess';
import SweetAlert from 'sweetalert2'
import LoaderBox from '../../../components/reuse/loaderbox';


const NutritionPlanList = (props) => {
  
  const userLocale = General.getLocale();
  const dispatch = useDispatch();

  const userInfo = useSelector(({auth}) => auth.authUser);
  const nutritionPlanData = useSelector(({nutritionPlan}) => nutritionPlan.fetchNutritionPlanData);
  const trainingsData = useSelector(({trainings}) => trainings.trainingsData);
  const addNutritionPlanRreponse = useSelector(({nutritionPlan}) => nutritionPlan.addNutritionPlanData);

  const trans = useSelector(({translation}) => translation.translationsData);
  const userIsAdmin = useSelector(({auth}) => auth.userIsAdmin);

  //SEARCH
  const [searchText, setSearchText] = useState('');
  var searchKeyword = "";

  //DATA
  const [curPage, setCurPage] = useState(0);
  const [email, setEmail] = useState(userInfo != null ? userInfo.email : "");
  const [descr, setDescr] = useState("");


  const addNutritionPlanData = useSelector(({nutritionPlan}) => nutritionPlan.addNutritionPlanData);
  const editNutritionPlanData = useSelector(({nutritionPlan}) => nutritionPlan.editNutritionPlanData);
  const deleteNutritionPlanData = useSelector(({nutritionPlan}) => nutritionPlan.deleteNutritionPlanData);
  const [addEditNutritionPlanModal, setAddEditNutritionPlanModal] = useState(false);
  const [nutritionPlanToEdit, setNutritionPlanToEdit] = useState(null);
  const [nutritionPlanIsEdit, setNutritionPlanIsEdit] = useState(false);

  useEffect(() => {
    if (addNutritionPlanData && addNutritionPlanData.status){
      getPlansData();
        }
    }, [addNutritionPlanData]);

    useEffect(() => {
        if (editNutritionPlanData && editNutritionPlanData.status){
          getPlansData();
        }
    }, [editNutritionPlanData]);


    useEffect(() => {
      if (deleteNutritionPlanData != null){
        if (deleteNutritionPlanData.status){
          toast.success(deleteNutritionPlanData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
            getPlansData();
        } else {
          toast.error(deleteNutritionPlanData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
        }
    
        dispatch({type: DELETE_TRACK, payload: null});
      }
    }, [deleteNutritionPlanData]);


    useEffect(() => {
      getPlansData();
    }, []);

 
  const getPlansData = () => {
    let postData = {}
    dispatch(getNutritionPlans(postData));
  }



  const handleUserClick = (e,item) => {
    e.preventDefault();
    setNutritionPlanToEdit(item);
    setNutritionPlanIsEdit(true);
    setAddEditNutritionPlanModal(true);
  }


  const askToDeleteNutritionPlan = (e,item) => {
    e.preventDefault();
    SweetAlert.fire({
      title:trans.delete_dialog_title,
      text:trans.delete_dialog_desc,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText:trans.ok_btn,
      cancelButtonText: trans.cancel_btn,
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
          //OK
            //REMOVE
          let postData = {itemId: item.id}
          dispatch(deleteNutritionPlan(postData));
      }
      else {
        
      }
    })
  }
 
  const getNutritionPlansUI = () => {
    var nutritionPlans = [];

    if (nutritionPlanData == null){
      return(<LoaderBox></LoaderBox>)
    }

    nutritionPlanData.data.map((item,i) => {

      nutritionPlans.push(<tr  key={`nutritionPlans_item_${i}`} className="_table_nutritionPlans_item">
                    <td>{item.title}</td>
                    <td>{item.dailyKcal}</td>
                    <td>{item.visible == true ? trans.blog_status_published  : trans.blog_status_unpublished}</td>
                    <td className="text-right">
                            {/* <Button style={{marginRight:'5px'}} onClick={(e) => {askToDeleteNutritionPlan(e,item)}} color="secondary" size="sm"><i className="fa fa-trash"></i> {trans.delete_btn}</Button> */}
                            <Button onClick={(e) => {handleUserClick(e,item)}} color="primary" size="sm"><i className="fa fa-pencil"></i> {trans.edit_btn}</Button>
                    </td>
                  </tr>)
      
    });

    if (nutritionPlans.length > 0){
      return (<div className="table-responsive">
      <table className="table card-table table-vcenter text-nowrap">
          <thead>
            <tr className="_table_nutritionPlans_item">
              <th key={"title"}>{trans.nutrition_menu_type_name}</th>
              <th key={"dailyKcal"}>{trans.nutrition_daily_kcal}</th>
              <th key={"active"}>{trans.visible}</th>

              <th key={"nutritionPlan_edit"}></th>
            </tr>
          </thead>
          <tbody>
            {nutritionPlans}
          </tbody>
        </table>
      </div>);
    } else {
      return (<div className="m-10">
          {trans.no_training_nutritionPlans}
        </div>)
    }

  }

  const addEditModaltoggle = (e) => {
    setNutritionPlanToEdit(null); 
    setNutritionPlanIsEdit(false); 
    setAddEditNutritionPlanModal(!addEditNutritionPlanModal)
  }

  const onSaveNutritionPlan = (data) => {
    if (nutritionPlanIsEdit){
      dispatch(editNutritionPlan(data));
    } else {
      dispatch(addNutritionPlan(data));
    }
  }

  return (
    <Fragment>
      <Breadcrumb parent={trans.nutrition_plans} title={trans.nutrition_plans} />
      <Container fluid={true}>



      {!userIsAdmin  ?
       <Col md="12">
       <NoAccess/>
       </Col>
       : 
        
       <div>
    
              <Card>
                <CardHeader >
                  <Row>
                  <Col xl="12" sm="12"> 
                    <div style={{alignItems: 'center',}}> 
                        <h4 className="card-title mb-0 font-weight-bold" style={{float: "left"}}>{trans.nutrition_plans}</h4>
                        <div className="card-title mb-0" style={{float: "right"}}>
                            <Button onClick={addEditModaltoggle} color="primary" size="sm"><i className="fa fa-pencil"></i> {trans.add_btn}</Button>
                        </div>
                    </div>
                  </Col>
                    </Row>
                </CardHeader>
                <div>
                      {getNutritionPlansUI()}
                    </div>

                </Card>
                
  </div>}
      </Container>
      <AddNutritionPlan
        open={addEditNutritionPlanModal}
        onAddNutritionPlanClose={addEditModaltoggle}
        onSaveNutritionPlan={(data) => onSaveNutritionPlan(data)}
        isEditNutritionPlan={nutritionPlanIsEdit}
        nutritionPlanToEdit={nutritionPlanToEdit}
        trainings={trainingsData != null ? trainingsData.trainings : null}
      />
    </Fragment>
  );
}

export default NutritionPlanList;