import './schedule.css';
// import 'react-big-calendar/lib/css/react-big-calendar.css'
import React, { Fragment,useState,useEffect,useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumb from '../../layout/breadcrumb'
import { toast } from 'react-toastify';
import { Container, Row, Col, Card, CardHeader, FormGroup, Input, Label,Dropdown,DropdownMenu, DropdownItem,Button,DropdownToggle } from 'reactstrap'
import { Loader } from 'react-bootstrap-typeahead';
import * as General from '../../util/General'
import { useHistory } from 'react-router';
import { addSchedule, getSchedules,deleteScheduleWorkout, editSchedule, cloneWeek } from '../../redux/schedule/action';
import { getTrainings } from '../../redux/trainings/action';
import { getTracks } from '../../redux/tracks/action';
import WorkoutsPicker from '../workouts/workoutsPicker';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import SweetAlert from 'sweetalert2'
import AddWorkoutModal from '../workouts/addWorkoutModal';
import NoAccess from '../../components/reuse/noaccess';
import { DELETE_SCHEDULE, EDIT_SCHEDULE_WORKOUT, USER_PROGRAM_TRACK, USER_PROGRAM_TRAINING } from '../../redux/actionTypes';


const ScheduleList = (props) => {
  
  // GENERAL VARS
  let elRef = useRef(null);
  const dispatch = useDispatch();

  // SELECTORS
  const schedulesData = useSelector(({schedules}) => schedules.schedulesData);
  const addSchedulesData = useSelector(({schedules}) => schedules.addScheduleData);
  const editSchedulesData = useSelector(({schedules}) => schedules.editScheduleData);
  const deleteSchedulesData = useSelector(({schedules}) => schedules.deleteScheduleData);
  const cloneWeekData = useSelector(({schedules}) => schedules.cloneWeekData);
  const trainingsData = useSelector(({trainings}) => trainings.trainingsData);
  const tracksData = useSelector(({tracks}) => tracks.tracksData);
  const trans = useSelector(({translation}) => translation.translationsData);
  const userIsAdmin = useSelector(({auth}) => auth.userIsAdmin);
  const userIsManager = useSelector(({auth}) => auth.userIsManager);
  const userIsCoach = useSelector(({auth}) => auth.userIsCoach);


  //STATE DATA
  const [curPage, setCurPage] = useState(0);
  const [selectedTraining, setSelectedTraining] = useState("");
  const [selectedTrack, setSelectedTrack] = useState("");
  const [scheduleEvents, setScheduleEvents] = useState([]);
  const [workoutsDataLoaded, setWorkoutsDataLoaded] = useState(false);
  const [addWorkoutToggle, setAddWorkoutToggle] = useState(false);
  const [addWorkoutFromEditSchedule, setAddWorkoutFromEditSchedule] = useState(false);
  const [singleWorkoutItem, setSingleWorkoutItem] = useState(null);
  const [reloadWorkoutData, setReloadWorkoutData] = useState(false);
  const [calendarStartDate, setCalendarStartDate] = useState(new Date());
  
  //VARIABLES
  var searchKeyword = "";

  
  
  let draggable = null;

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  

  //HOOKS
  useEffect(() => {
    getTrainingssData();
  }, []);

  useEffect(() => {
    setScheduleEvents([]);
    if (schedulesData != null && schedulesData.schedules.length > 0){
      var newSchdedules = [];
      //LOOP TROUGHT THE DATA TO FIND THE WORKOUTS IN EACH SCHEDULE
      schedulesData.schedules.forEach(schedule => {
        //NOW THAT WE HAVE EACH ITEM WE CAN LOOP THROUGH THE WORKOUTS
        schedule.workouts.forEach(scheduleWorkout => {
          //NOW WE WILL CREATE AN OBJECT FOR EACH WORKOUT
          //WE GIVE EACH EVENT THE DATE OF THE SCHEDULE OBJECT 
          //BUT THE CONTENTN IS BASICALLY A WORKOUT OBJECT
          var stateColor = "#fff" //DEFAULT RED T
          let eventItem = {
            'title': scheduleWorkout.workout.name,
            'start': new Date(Date.parse(scheduleWorkout.eventDate)) ,
            'end': new Date(Date.parse(scheduleWorkout.eventDateEnd)),
            'resource': scheduleWorkout,
            'color':stateColor,
            'desc': scheduleWorkout.workout.desc,
            'allDay': scheduleWorkout.allDay,
            'id': scheduleWorkout.id,
            'eventOrder': scheduleWorkout.order
           };
          newSchdedules.push(eventItem);
        });
        
      });
      setScheduleEvents(newSchdedules);
    }
  }, [schedulesData]); 


  useEffect(() => {
    if (trainingsData != null && trainingsData.trainings.length > 0){
      setSelectedTrack("");
      if (selectedTraining == ""){ //THE FIRST TIME WE SET THE FIRST TRAINING AS DEFAULT
        var userProgramTraining = localStorage.getItem(USER_PROGRAM_TRAINING);
        if (userProgramTraining != null && userProgramTraining != ""){
          trainingsData.trainings.forEach(element => {
            if (element.id == userProgramTraining){
              setSelectedTraining(element.id);
              getTracksData(element.id);
            }
          });
        } else {
          setSelectedTraining(trainingsData.trainings[0].id);
          getTracksData(trainingsData.trainings[0].id);
        }
      } else {
        getTracksData(selectedTraining);
      }
    }
  }, [trainingsData]); 


  useEffect(() => {
    if (tracksData != null){
      if (tracksData.tracks.length > 0){
        if (selectedTrack == ""){
          var userProgramTrack = localStorage.getItem(USER_PROGRAM_TRACK);
          var foundTrack = false;
          if (userProgramTrack != null && userProgramTrack != ""){
            tracksData.tracks.forEach(element => {
              if (element.id == userProgramTrack){
                setSelectedTrack(element.id);
                getSchedulesData(element.id);
                foundTrack = true;
              }
            });
          }
          if (!foundTrack){
            setSelectedTrack(tracksData.tracks[0].id)
            getSchedulesData(tracksData.tracks[0].id);
          }
        
        } else {
          getSchedulesData(selectedTrack);
        }
        
      }
    }
  }, [tracksData]); 

  useEffect(() => {
    if (addSchedulesData != null && addSchedulesData.status){
      getSchedulesData(selectedTrack);
    }
 
  }, [addSchedulesData]);

  useEffect(() => {
    if (editSchedulesData != null){
      if (editSchedulesData.status){
        getSchedulesData(selectedTrack);
      }else {
        toast.error(editSchedulesData.message, {
          position: toast.POSITION.TOP_RIGHT
          });
        }
    
    dispatch({type: EDIT_SCHEDULE_WORKOUT, payload: null});
    }
  }, [editSchedulesData]);

  
  useEffect(() => {
    if (deleteSchedulesData != null){
      if (deleteSchedulesData.status){
        getSchedulesData(selectedTrack);
      }else {
        toast.error(deleteSchedulesData.message, {
          position: toast.POSITION.TOP_RIGHT
          });
        }
    }
    dispatch({type: DELETE_SCHEDULE, payload: null});
 
  }, [deleteSchedulesData]);

  useEffect(() => {
    if (cloneWeekData != null && cloneWeekData.status){
      getSchedulesData(selectedTrack);
    }
 
  }, [cloneWeekData]);

  useEffect(() => {
    if (calendarStartDate != null){
      getSchedulesData(selectedTrack);
    }
 
  }, [calendarStartDate]);

  

  useEffect(() => {
    if (workoutsDataLoaded ){
      let draggableEl = document.getElementById("workout-events");
      let isDragging = false;

        // console.log(draggableEl);
        //CREATE A LISTENER THAT WILL LISTEN TO DRAGS THAT ARE HAPPENING TO THE WORKOUT PICKER
        //THIS WILL RETURN THE ITEM TO THE CALENDAR AND THE CALENDAR IMPLEMENTATION WILL HANDLE IT
        if (draggableEl != null){
        draggable = new Draggable(draggableEl, {
            itemSelector: "._workout_picker_item",
            eventData: function (eventEl) {
                let title = eventEl.getAttribute("title");
                let id = eventEl.getAttribute("data");
                let eventInfo = {
                  title: title,
                  id: id
                };
              // addNewEvent(eventInfo);
                return eventInfo;
            }
        });

          // Mouse down event to start dragging
    const handleMouseDown = () => {
      isDragging = true;
    };

    // Mouse up event to stop dragging
    const handleMouseUp = () => {
      isDragging = false;
    };

    // Attach auto-scroll only if dragging is active
    const handleAutoScrollWithCheck = (event) => {
      if (isDragging) {
        handleAutoScroll(event);
      }
    };

        // Attach event listeners
        draggableEl.addEventListener("mousedown", handleMouseDown);
        document.addEventListener("mouseup", handleMouseUp);
        document.addEventListener("mousemove", handleAutoScrollWithCheck);

        setReloadWorkoutData(false);
        return () => {
          draggable.destroy();
          draggableEl.removeEventListener("mousedown", handleMouseDown);
          document.removeEventListener("mouseup", handleMouseUp);
          document.removeEventListener("mousemove", handleAutoScrollWithCheck);
        };
      }
    }
  },[workoutsDataLoaded]);

 
  //API CALLS
  const getSchedulesData = (track) => {
  //  var weekOfMonth = General.getISOWeekInMonth(calendarStartDate);
    var month = General.getISOMonth(calendarStartDate);
    var nextMonth = month + 1;
    var prevMonth = month - 1;
    var year = General.getISOYear(calendarStartDate);
   // var dayOfMonth = calendarStartDate.getDay();
    var query = {};
    console.log("SELECTED_MONTH",month);
    if (month == 1){
      query = {'$and': [
        {'$or':[{month: month,year},{month: 2,year: year},{month: 12,year: year-1}]},
      ]};
    } else {
      if (nextMonth == 13){
        query = {'$and': [
          {'$or':[{month: month,year},{month: 1,year: year+1},{month: prevMonth,year}]},
        ]};
      } else {
        query = {'$and': [
          {'$or':[{month: month},{month: nextMonth},{month: prevMonth}]},
          {year},
        ]};
      }
    }
    console.log("SELECTED_MONTH",query);
   
    query.trackId = track;
    let postData = {page: curPage+1,sort: {"_id": -1},query: query,searchKeyword: searchKeyword}
    dispatch(getSchedules(postData));
  }
 
  const getTrainingssData = () => {
    let postData = {page: curPage+1,sort: {"_id": 1},query: {},searchKeyword: searchKeyword}
    dispatch(getTrainings(postData));
  }

  const getTracksData = (training) => {
    let postData = {page: curPage+1,sort: {"_id": 1},query: {training_id: training},searchKeyword: searchKeyword}
    dispatch(getTracks(postData));
  }


  //FUNCTIONS
  const handleTrainingChange = (e,item) => {
    setSelectedTraining(item);
    setSelectedTrack("");
    getTracksData(item)
    localStorage.setItem(USER_PROGRAM_TRAINING, item);
  }

  const handleTrackChange = (e,item) => {
    setSelectedTrack(item);
    getSchedulesData(item);
    localStorage.setItem(USER_PROGRAM_TRACK, item);
  }

  const addWorkout = (e) => {
    e.preventDefault();
    setSingleWorkoutItem(null);
    setAddWorkoutFromEditSchedule(false);
    setAddWorkoutToggle(true);
  }

  const onWorkoutStored = () => {
    console.log("WORKOUT STORED");
    setReloadWorkoutData(true);
    setAddWorkoutToggle(false);
  }

  const onSchdeuleWorkoutEdited = () => {
    getSchedulesData(selectedTrack);
    setAddWorkoutToggle(false);
  }

  const onAddWorkoutModalClose = () => {
      setSingleWorkoutItem(null);
      setAddWorkoutFromEditSchedule(false);
      setAddWorkoutToggle(false);
  }
  
  
  const getEventWorkoutByID = (id) => {
    let event = null;
    //LETS FIND THE EVENT
    schedulesData.schedules.forEach(schedule => {
      schedule.workouts.forEach(workout => {
        //console.log(workout);
      //  console.log(`${workout.id} ${eventInfo.event.id}`);
        if (workout.id == id){
          event = workout;
        }
      });
   
    });
    return event;
  }

  const addNewEvent = (eventInfo,event,fromEdit) => {
    // console.log("EVENT_TO_ADD",event);
    // console.log(new Date(event.start).getDate());

    let eventId = ""
    if (fromEdit){
      let schedule = getEventWorkoutByID(event.id);
      //console.log(workout);
      eventId = schedule.workout.id;
    } else {
      eventId = event.id;
    }

  //  console.log(eventId);

    let postData = {
      "trackId": selectedTrack,
      "dayOfMonth": new Date(event.start).getDate(),
      "year": General.getISOYear(new Date(event.start)),
      "month": General.getISOMonth(new Date(event.start)),
      "workouts": [
        {"order": 0, 
        "workout": eventId,
        "startHour": new Date(event.start).getHours(),
        "startMinute": new Date(event.start).getMinutes(),
        "endHour": new Date(event.end).getHours(),
        "endMinute": new Date(event.end).getMinutes(),
        "allDay": event.allDay
      }],
      "publish_date": new Date(),
    }
    //console.log(postData);
    eventInfo.revert();
    dispatch(addSchedule(postData))
  }

  const editScheduleWorkout = (e,item) => {
    e.preventDefault();
    //console.log(item);
     let workout = getEventWorkoutByID(item.event.id);
    // const win = window.open(`/app/workouts/single/${workout.workout.id}`, "_blank");
    // win.focus();
    //console.log(workout);
    setSingleWorkoutItem(workout);
    setAddWorkoutToggle(true);
    setAddWorkoutFromEditSchedule(true);
  }

  const askDeleteScheduleWorkout = (e,item) => {
    //console.log(item);
    SweetAlert.fire({
      title:trans.delete_dialog_title,
      text:trans.delete_dialog_desc,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText:trans.ok_btn,
      cancelButtonText: trans.cancel_btn,
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
          //OK
            //REMOVE
          let postData = {workoutId: item.event.id, scheduleId: ""}
          dispatch(deleteScheduleWorkout(postData));
      }
      else {
        
      }
    })
  }

  const handleeventDrop = (eventInfo) => {
    addNewEvent(eventInfo,eventInfo.event,false);
  }

  const handleEventChange = (eventInfo) => {
    //console.log(eventInfo);
      let newEvent = eventInfo.event;
    let oldEvent = eventInfo.oldEvent;

    let oldStartDate = new Date(oldEvent.start);
 
    let newStartDate = new Date(newEvent.start);
    let newEndDate = new Date(newEvent.end);
    
    // console.log(oldStartDate);
    // console.log(newStartDate);

    if (oldStartDate.getDate() != newStartDate.getDate() ||
        oldStartDate.getMonth() != newStartDate.getMonth() || 
        oldStartDate.getFullYear() != newStartDate.getFullYear()){
          //console.log("NOT_THE_SAME",newEvent);
          SweetAlert.fire({
            title:trans.move_workout_day_title,
            text:trans.move_workout_day_desc,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText:trans.ok_btn,
            cancelButtonText: trans.cancel_btn,
            reverseButtons: true
          }).then((result) => {
            if (result.value) {
                //OK
                  //REMOVE AND ADD
                let postData = {workoutId: oldEvent.id, scheduleId: ""}
                dispatch(deleteScheduleWorkout(postData));
                //ADD THE NEW ONE
                addNewEvent(eventInfo,newEvent,true);
            }
            else {
              eventInfo.revert();
            }
          })
        
        }else {
         //console.log("SAME");
          let newWorkouts = [];
          let scheduleId = "";
          //WE NEED TO GET THE DATA AND SEND THEM TO SERVER AS EDITED
          //WE NEED TO SEND ALL THE WORKOUTS OF THE SCHEDULE
          schedulesData.schedules.forEach((schedule,scheduleIndex) => {
            //console.log(schedule);
            schedule.workouts.forEach((workout,workoutIndex) => {
              // console.log(workout);
              // console.log(oldEvent.i);
           
              if (workout.id == oldEvent.id) {
                  //console.log("FOUND_SAME");
                  scheduleId = schedule.id;
                  var newWorkout = workout;
                  newWorkout.startHour = newStartDate.getHours();
                  newWorkout.startMinute = newStartDate.getMinutes();
                  newWorkout.endHour = newEndDate.getHours();
                  newWorkout.endMinute = newEndDate.getMinutes();
                  newWorkouts.push(newWorkout);
              }
             
            });
          });
          //NOW THAT WE HAVE ALL THE STUFF. LETS SEND THEM TO SERVER
          let postData = {scheduleId: scheduleId, workouts: newWorkouts};
          dispatch(editSchedule(postData));
        }
  }

  const handleEventDragStop = (eventInfo) => {
    //console.log(eventInfo);
  }

  function renderEventContent(eventItem) {
    //console.log("ILOGEVENT");
    var videoView = null;

//    console.log("eventItem",eventItem);

   
    let event = getEventWorkoutByID(eventItem.event.id);

  //  console.log("event",event);
    //IF THE EVENT IS NULL THAT MEANS ITS COMING FROM THE DRAG AND IT DOES NOT MATCH THE ID

    if (event != null ){

    if (event.workout.videogallery.length > 0){
        videoView = (
          <div className="_workout_item_content_bg">
             <div >
             <ul className="_workout_item_p">
               { event.workout.videogallery.map((item,index) => {
                 var myId = General.getId(item);
                   return (<li key={`video_url_${item}`}>
                     <iframe className="_workout_video" width="200" height="150" src={`//www.youtube.com/embed/${myId}`} frameBorder="0"  allow="fullscreen;"></iframe>
                 </li>)
                 })}
               </ul>
           </div>
          </div>
        );
    }

 

    return(<div 
            key={eventItem.id}  >

         <div 
            className="_event_container">
            <div className="_event_text_title_container">
           <span className="_event_text_title_text">{event.workoutdata.name}</span>
        </div>
        
        
        <div>
         <div className="_event_text" dangerouslySetInnerHTML={{__html: event.workoutdata.desc}} />
        </div>
        
        {/* {videoView} */}
           </div>     
       
        <div className="_event_actions"> 
           <i style={{cursor:'pointer',color:'black',fontSize: '14px'}} onClick={(e)=> {editScheduleWorkout(e,eventItem)}}  className="fa fa-pencil-square"></i>
           <i style={{cursor:'pointer',color:'red',fontSize: '14px',marginLeft:'4px'}} onClick={(e)=> {askDeleteScheduleWorkout(e,eventItem)}}  className="fa fa-trash"></i>
        </div>
      </div>);
    }
  }

  const checkToCloneWeek = (e) => {
      var month = General.getISOMonth(calendarStartDate);
      var year = General.getISOYear(calendarStartDate);
    SweetAlert.fire({
      title:trans.schedule_clone_week,
      text:trans.schedule_clone_week_descr,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText:trans.ok_btn,
      cancelButtonText: trans.cancel_btn,
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
          //OK
            //REMOVE
           let postData = {curDate: calendarStartDate,trackId: selectedTrack,month: month,year: year}
           dispatch(cloneWeek(postData));
      }
      else {
        
      }
    })
  }

  const onWorkoutReloaded = () => {
    setReloadWorkoutData(false);
  }

  const handleAutoScroll = (event) => {
    const scrollSpeed = 10; // Speed of scrolling
    const scrollBoundary = 50; // Pixels near the edge to trigger scrolling
  
    // Scroll the calendar container
    const calendarScroller = document.querySelector('.fc-scroller');
    if (calendarScroller) {
      const rect = calendarScroller.getBoundingClientRect();
  
      if (event.clientY > rect.bottom - scrollBoundary) {
        calendarScroller.scrollTop += scrollSpeed;
      } else if (event.clientY < rect.top + scrollBoundary) {
        calendarScroller.scrollTop -= scrollSpeed;
      }
    }
  
    // Scroll the browser window
    if (event.clientY > window.innerHeight - scrollBoundary) {
      window.scrollBy(0, scrollSpeed);
    } else if (event.clientY < scrollBoundary) {
      window.scrollBy(0, -scrollSpeed);
    }
  };
  

  return (
    <Fragment>
      <Breadcrumb parent={trans.schedules_title} title={trans.schedules_title} />
      <Container fluid={true}>

      <Row>
    

      { userIsAdmin || userIsManager || userIsCoach ?
    
         trainingsData != null && tracksData != null ? 
            <Col md="12">
              <Card>
                <CardHeader className="_schedule_header" >
                  <Row>
                      <Col md='4'>
                      {trainingsData != null ?
                                <FormGroup>
                                    <Label for="exampleSelect">{trans.training_name}</Label>
                                    <Input className="custom-select" value={selectedTraining} onChange={(e)=> handleTrainingChange(e,e.target.value)} type="select" name="selectedTraining" id="selectedTraining">
                                        {trainingsData.trainings.map((item,index) => {
                                           return(<option key={item.id} value={item.id}>{item.title}</option>)
                                        })}
                                    </Input>
                                </FormGroup>
                                : null}
                      </Col>

                      <Col md='4'>
                      {trainingsData != null ?
                                <FormGroup>
                                    <Label for="exampleSelect">{trans.track_name}</Label>
                                    <Input className="custom-select" value={selectedTrack} onChange={(e)=> handleTrackChange(e,e.target.value)} type="select" name="selectedTrack" id="selectedTrack">
                                        {tracksData.tracks.map((item,index) => {
                                           
                                         return(<option key={item.id} value={item.id}>{item.title}</option>)
                                            
                                        })}
                                    </Input>
                                </FormGroup>
                                : null}
                      </Col>

                      <Col>
                      <div style={{marginTop:'28px', float: 'right', marginRight:'15px'}}>
                        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                          <DropdownToggle color="black" caret >
                            {trans.schedule_dropdown_btn}
                          </DropdownToggle>
                          <DropdownMenu>
                            {/* <DropdownItem header>Header</DropdownItem>
                            <DropdownItem>Some Action</DropdownItem>
                            <DropdownItem text>Dropdown Item Text</DropdownItem>
                            <DropdownItem disabled>Action (disabled)</DropdownItem>
                            <DropdownItem divider /> */}
                            <DropdownItem onClick={(e)=>{checkToCloneWeek(e)}}>{trans.schedule_clone_week}</DropdownItem>
                            {/* <DropdownItem>Bar Action</DropdownItem>
                            <DropdownItem>Quo Action</DropdownItem> */}
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                      </Col>

                      <Col>
                      <div style={{marginTop:'28px', float: 'right', marginRight:'15px'}}>
                        <button
                              className="btn btn-primary"
                                  onClick={(e) => {addWorkout(e)}}>
                              {trans.add_btn}
                          </button>
                      </div>
                      </Col>
                   

                  </Row>
                </CardHeader>
       
                <Row>
                  <Col md={"9"}> 
                    <div className="_schedule_calendar">
                      <FullCalendar
                       plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                       headerToolbar={{
                         left: "prev,next today",
                         center: "title",
                         right: "dayGridMonth,timeGridWeek,timeGridDay"
                       }}
                        initialView="timeGridWeek"
                        weekends={true}
                        droppable={true}
                        editable={true}
                        selectable={true}
                        events={scheduleEvents}
                        eventReceive={handleeventDrop}
                        eventChange={handleEventChange}
                        eventContent={renderEventContent}
                        eventDragStop={handleEventDragStop}
                        datesSet={(dateSet) => setCalendarStartDate(dateSet.start)}
                        firstDay={1}
                      />
                    </div>
                  </Col>


                  <Col md="3"> 
                  <div style={{marginTop:'15px',marginRight:'10px'}}>
                  
                               <WorkoutsPicker
                                    elRef={elRef}
                                    reloadWorkoutData={reloadWorkoutData}
                                    workoutsDataLoaded={(status) => {setWorkoutsDataLoaded(status)}}
                                    weekOfMonth={General.getISOWeekInMonth(calendarStartDate)}
                                    month={General.getISOMonth(calendarStartDate)}
                                    year={General.getISOYear(calendarStartDate)}
                                    onWorkoutReloaded={onWorkoutReloaded}
                                  />

                  </div>
                    
                  </Col>
                
              

                </Row>

               

              </Card>
            </Col>
             : <Loader/>   : <Col md="12">
             <NoAccess/>
             </Col>}
          </Row>
       
      </Container>

      <AddWorkoutModal
        currentDate={calendarStartDate}
        onSchdeuleWorkoutEdited={onSchdeuleWorkoutEdited}
        singleWorkoutItem={singleWorkoutItem}
        fromEditSchedule={addWorkoutFromEditSchedule}
        open={addWorkoutToggle}
        onAddWorkoutModalClose={onAddWorkoutModalClose}
        onWorkoutStored={onWorkoutStored}
        fromSchedule={true}
      />

    </Fragment>
  );
}

export default ScheduleList;