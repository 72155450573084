import './workouts.css';
import React, { Fragment,useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardFooter, Media, Button, Form, FormGroup, Input, Pagination, PaginationItem, PaginationLink,CardBody,Label } from 'reactstrap'

import parse from 'html-react-parser';


import {DATE_TIME_FORMAT, Follower,Following,TotalPost} from '../../constant'
import axios from 'axios'
import { DELETE_WORKOUT, STORED_TRANSLATIONS } from '../../redux/actionTypes';
import { Loader } from 'react-bootstrap-typeahead';
import Moment from 'react-moment';
import * as General from '../../util/General'
import { editWorkout, getWorkouts,deleteWorkout } from '../../redux/workouts/action';
import { toast } from 'react-toastify';
import AddWorkout from './addWorkout';
import NoAccess from '../../components/reuse/noaccess';
import SweetAlert from 'sweetalert2'
import LoaderBox from '../../components/reuse/loaderbox';
import AddWorkoutModal from '../workouts/addWorkoutModal';

const WorkoutsList = (props) => {
  
  const userLocale = General.getLocale();
  const dispatch = useDispatch();

  const userInfo = useSelector(({auth}) => auth.authUser);
  const workoutsData = useSelector(({workouts}) => workouts.workoutsData);
  const addWorkoutsRreponse = useSelector(({workouts}) => workouts.addWorkoutsData);
  const deleteWorkoutRreponse = useSelector(({workouts}) => workouts.deleteWorkoutData);

  const trans = useSelector(({translation}) => translation.translationsData);
  const userIsAdmin = useSelector(({auth}) => auth.userIsAdmin);
  const userIsManager = useSelector(({auth}) => auth.userIsManager);
  const userIsCoach = useSelector(({auth}) => auth.userIsCoach);

  //MODAL
  const [addWorkoutToggle, setAddWorkoutToggle] = useState(false);
  const [singleWorkoutItem, setSingleWorkoutItem] = useState(null);
  const [addWorkoutFromEditSchedule, setAddWorkoutFromEditSchedule] = useState(false);
  const [isEditWorkout,setIsEditWorkout] = useState(false);


  //SEARCH
  const [searchText, setSearchText] = useState('');
  var searchKeyword = "";

  //DATA
  const [curPage, setCurPage] = useState(1);
  const [email, setEmail] = useState(userInfo != null ? userInfo.email : "");
  const [descr, setDescr] = useState("");
  const [addWorkoutsData, setAddWorkoutsData] = useState(null);


  const [workoutsList, setWorkoutsList] = useState([]);

  const [isFetching, setIsFetching] = useState(false);

  var currentDate = new Date();

  //PAGINATION STUFF
  const isScrolling =()=>{
    if(window.innerHeight + document.documentElement.scrollTop!==document.documentElement.offsetHeight){
      return;
    }
    setIsFetching(true)
  }

  useEffect(() => {
    if (isFetching){
      setCurPage(curPage+1)
    }
  }, [isFetching]);

  //END OF PAGINATATION STUFF

  useEffect(() => {
    getWorkoutssData();
    window.addEventListener("scroll", isScrolling);
    return () => window.removeEventListener("scroll", isScrolling);
  }, []);

  useEffect(() => {
    console.log(workoutsData);
    if (workoutsData != null){

  
      if (workoutsData.cur_page == 1){
        setWorkoutsList(workoutsData.workouts)
      } else {
        setWorkoutsList([...workoutsList, ...workoutsData.workouts]);
        setIsFetching(false);
      }

    }
  

  }, [workoutsData]);

 
  const getWorkoutssData = () => {
    let postData = {page: curPage,sort: {"_id": -1},query: {},limit: 10,searchKeyword: searchKeyword,hasPaginate: true}
    dispatch(getWorkouts(postData));
  }

  useEffect(() => {
    if (curPage > 1){
      getWorkoutssData();
    }
  }, [curPage]);

  useEffect(() => {
    if (deleteWorkoutRreponse != null){
      if (deleteWorkoutRreponse.status){
        toast.success(deleteWorkoutRreponse.message, {
          position: toast.POSITION.TOP_RIGHT
          });
          getWorkoutssData();
      } else {
        toast.error(deleteWorkoutRreponse.message, {
          position: toast.POSITION.TOP_RIGHT
          });
      }
  
      dispatch({type: DELETE_WORKOUT, payload: null});
    }
  }, [deleteWorkoutRreponse]);


  const onWorkoutStored = () => {
    onAddWorkoutModalClose();
    getWorkoutssData();
   
  }

  const editWorkout = (e,editWorkout) => {
    e.preventDefault();
    setSingleWorkoutItem(editWorkout);
    setAddWorkoutToggle(true);
    setIsEditWorkout(true);
    setAddWorkoutFromEditSchedule(false);
  }
  

  const onSchdeuleWorkoutEdited = () => {
    getWorkoutssData();
    setAddWorkoutToggle(false);
  }


  const onAddWorkoutModalClose = () => {
    setSingleWorkoutItem(null);
    setAddWorkoutFromEditSchedule(false);
    setAddWorkoutToggle(false);
    setIsEditWorkout(false);
}



  const askDeleteWorkout = (e,item) => {
    e.preventDefault();
    console.log(item);
    SweetAlert.fire({
      title:trans.delete_dialog_title,
      text:trans.delete_dialog_desc,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText:trans.ok_btn,
      cancelButtonText: trans.cancel_btn,
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
          //OK
            //REMOVE
          let postData = {workoutId: item.id}
          dispatch(deleteWorkout(postData));
      }
      else {
        
      }
    })
  }

  

  return (
    <Fragment>
      <Breadcrumb parent={trans.workouts_title} title={trans.workouts_title} />
      <Container fluid={true}>

      {userIsManager || userIsCoach ||  userIsAdmin ? 

      <Row>


      <Col md="5">
        <AddWorkout
          onWorkoutStored={onWorkoutStored}
          fromSchedule={false}
          currentDate={currentDate}
        />
      </Col>

      {workoutsData != null ? 
            <Col md="7">
              <Card>
                <CardHeader >
                  <Row>
                  <Col xl="12" sm="12"> 
                    <div style={{alignItems: 'center',}}> 
                     <h4 className="card-title mb-0 font-weight-bold" style={{float: "left"}}>{trans.workouts_title}</h4>
                        
                    </div>
                  </Col>
                  <br/>
                  <br/>
                 
                    </Row>
                </CardHeader>
                <div className="table-responsive">
                  {workoutsList.map((item,i) => {
                        // let values = item.values.map((value,valIndex) => {
                        //    return ( <span key={`trans_value_${i}_val_${valIndex}`} className="_translation_value">{`${value.langLoc} - ${value.value}`}</span>)
                        // })

                        var privateText = item.access_type == 1 ? trans.value_yes : trans.value_no;
                        var active = item.active ? trans.value_status_true : trans.value_status_true; 
                      
                        var find = '<a';
                        var re = new RegExp(find, 'g');

                        var descData = item.desc;
                        var htmlData =  descData.replace(re, '<a target="_blank" ');
                       // console.log("htmlData",htmlData);

                        return (<div  key={`workouts_item_${i}`} className="_workout_item">
                            <div>
                              <div className="_workout_list_item_header_bg">
                                <div style={{float:'left'}}>
                               
                                  <h5   className="_workout_list_item_header"> <i className="icofont icofont-gym-alt-3"></i> {item.name}</h5>
                                </div>
                                <div style={{float:'right', padding:'4px',color:'white',cursor:'pointer'}}>
                                  <i style={{cursor:'pointer',marginRight:'10px'}} onClick={(e)=> {askDeleteWorkout(e,item)}}  className="fa fa-trash"></i>
                                  <i style={{cursor:'pointer'}} onClick={(e)=> {editWorkout(e,item)}}  className="fa fa-pencil"></i>
                                </div>
                              </div>

                  
                              <div className="_workout_item_content">
                            
                              {item.desc != "" ?
                               <div className="_workout_item_content_bg">
                                <div className="_workout_item_content_header_bg">
                                  <h6 className="_workout_list_item_header">{trans.workout_desc}</h6>
                                </div>
                                 <div className="_workout_item_p">  { parse(htmlData) }   </div> 
                               </div>: null}
                   
                               {item.amount != "" &&  item.scoring.name != ""?
                               <div className="_workout_item_content_bg">
                                <div className="_workout_item_content_header_bg">
                                  <h6 className="_workout_list_item_header">{trans.workout_scoring}</h6>
                                </div>
                                 <p className="_workout_item_p">{item.scoring.amount} {trans.workout_set} {trans.workout_of} {item.scoring.name}</p>
                               </div>: null}
                               
                               {item.coachNotes != ""?
                               <div className="_workout_item_content_bg">
                                <div className="_workout_item_content_header_bg">
                                  <h6 className="_workout_list_item_header">{trans.workout_coach_notes}</h6>
                                </div>
                                 <p className="_workout_item_p">{ parse(item.coachNotes) } </p>
                               </div>: null}
                             
                               {item.athleteNotes != ""?
                               <div className="_workout_item_content_bg">
                                <div className="_workout_item_content_header_bg">
                                  <h6 className="_workout_list_item_header">{trans.workout_athlete_notes}</h6>
                                </div>
                                 <p className="_workout_item_p">{ parse(item.athleteNotes) }</p>
                               </div>: null}
                             
                              {item.videogallery.length > 0 ? 
                               <div className="_workout_item_content_bg">
                                  <div className="_workout_item_content_header_bg">
                                    <h6 className="_workout_list_item_header">{trans.workout_videos}</h6>
                                  </div>
                                  <div >
                                  <ul className="_workout_item_video_ul">
                                    {item.videogallery.map((item,index) => {
                                       var myId = General.getId(item);
                                        return (<li key={`video_url_${item}`}>
                                          <iframe className="_workout_video" width="200" height="150" src={`//www.youtube.com/embed/${myId}`} frameBorder="0"  allow="fullscreen;"></iframe>
                                      </li>)
                                      })}
                                    </ul>
                                </div>
                               </div> : null}
                             
                              <br/>

                      
                              </div>
                            

                            </div>
                           
                        </div>)
                      }
                      )}
                </div>
              </Card>
            </Col>
             : <LoaderBox/>}
          </Row> : <Col md="12">
       <NoAccess/>
       </Col>
        }


<   AddWorkoutModal
        onSchdeuleWorkoutEdited={onSchdeuleWorkoutEdited}
        singleWorkoutItem={singleWorkoutItem}
        fromEditSchedule={addWorkoutFromEditSchedule}
        open={addWorkoutToggle}
        onAddWorkoutModalClose={onAddWorkoutModalClose}
        onWorkoutStored={onWorkoutStored}
        fromSchedule={true}
        isEdit={isEditWorkout}
        currentDate={currentDate}
      />

      </Container>
    </Fragment>
  );
}

export default WorkoutsList;