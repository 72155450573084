import './scoreTypes.css';
import React, { Fragment,useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardFooter, Media, Button, Form, FormGroup, Input, Pagination, PaginationItem, PaginationLink,CardBody,Label } from 'reactstrap'

import {DATE_TIME_FORMAT, Follower,Following,TotalPost} from '../../constant'
import axios from 'axios'
import { STORED_TRANSLATIONS } from '../../redux/actionTypes';
import { Loader } from 'react-bootstrap-typeahead';
import Moment from 'react-moment';
import * as General from '../../util/General'
import { getScoreTypes } from '../../redux/scoretypes/action';
import { toast } from 'react-toastify';
import NoAccess from '../../components/reuse/noaccess';

const ScoreTypesList = (props) => {
  
  const userLocale = General.getLocale();
  const dispatch = useDispatch();

  const userInfo = useSelector(({auth}) => auth.authUser);
  const scoreTypesData = useSelector(({scoreTypes}) => scoreTypes.scoreTypesData);
  const addScoreTypesRreponse = useSelector(({scoreTypes}) => scoreTypes.addScoreTypesData);

  const trans = useSelector(({translation}) => translation.translationsData);
  const userIsAdmin = useSelector(({auth}) => auth.userIsAdmin);

  //SEARCH
  const [searchText, setSearchText] = useState('');
  var searchKeyword = "";

  //DATA
  const [curPage, setCurPage] = useState(0);
  const [email, setEmail] = useState(userInfo != null ? userInfo.email : "");
  const [descr, setDescr] = useState("");
  const [addScoreTypesData, setAddScoreTypesData] = useState(null);



  useEffect(() => {
    getScoreTypessData();
}, []);

 
  const getScoreTypessData = () => {
    let postData = {page: curPage+1,sort: {"_id": -1},query: {},searchKeyword: searchKeyword}
    dispatch(getScoreTypes(postData));
}

  useEffect(() => {
    getScoreTypessData();
  }, [curPage]);


  const addEditScoreTypesModaltoggle = () => {

  }

  const handleSearchKeyword = async (keyword)   => {
    searchKeyword = keyword;
    setSearchText(keyword);
    getScoreTypessData();
  }

  const handleScoreTypesEditClick = (e,user) => {
    e.preventDefault();
    // history.push(`/app/users/userEdit/${user.nickname}`);
  }

  const removeScoreTypes = (user)=> {

  }

  const handleFeedBackAnswer = (e,item) => {
    e.preventDefault();
    if ( item != null){
      window.location = `mailto:${item.email}`
    }
  }

  const handlePageClick = (e,newPage) => {
    e.preventDefault();
    setCurPage(newPage);
  }




  return (
    <Fragment>
      <Breadcrumb parent={trans.scoreTypes_title} title={trans.score_types_title} />
      <Container fluid={true}>

      {!userIsAdmin ?
       <Col md="12">
       <NoAccess/>
       </Col>
       : 

      <Row>
    

      {userIsAdmin && scoreTypesData != null ? 
            <Col md="12">
              <Card>
                <CardHeader >
                  <Row>
                  <Col xl="12" sm="12"> 
                    <div style={{alignItems: 'center',}}> 
                     <h4 className="card-title mb-0 font-weight-bold" style={{float: "left"}}>{trans.score_types_title}</h4>
                        
                    </div>
                  </Col>
                  <br/>
                  <br/>
                 
                    </Row>
                </CardHeader>
                <div className="table-responsive">
                <table className="table card-table table-vcenter text-nowrap">
                    <thead>
                      <tr className="_table_scoreTypes_item">
                        <th key={"email_field"}>{trans.score_type_name}</th>
                      </tr>
                    </thead>
                    <tbody>

                      {scoreTypesData.scoretypes.map((item,i) => {
                        // let values = item.values.map((value,valIndex) => {
                        //    return ( <span key={`trans_value_${i}_val_${valIndex}`} className="_translation_value">{`${value.langLoc} - ${value.value}`}</span>)
                        // })

                        return (<tr  key={`scoreTypes_item_${i}`} className="_table_scoreTypes_item">
                          <td>{item.name}</td>
                      
                        </tr>)
                      }
                      )}
                      
                    </tbody>
                  </table>
                </div>
              </Card>
            </Col>
             : <Loader/>}
          </Row>
       }
      </Container>
    </Fragment>
  );
}

export default ScoreTypesList;