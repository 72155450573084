import './nutritionMenu.css';
import React, { Fragment,useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumb from '../../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardFooter, Media, Button, Form, FormGroup, Input, Pagination, PaginationItem, PaginationLink,CardBody,Label } from 'reactstrap'

import {DATE_TIME_FORMAT, Follower,Following,TotalPost} from '../../../constant'
import axios from 'axios'
import { DELETE_TRACK, STORED_TRANSLATIONS } from '../../../redux/actionTypes';
import { Loader } from 'react-bootstrap-typeahead';
import Moment from 'react-moment';
import * as General from '../../../util/General'
import {getNutritionMenu, addNutritionMenu, deleteNutritionMenu, editNutritionMenu } from '../../../redux/nutrition/nutritionMenu/action';
import { toast } from 'react-toastify';
import AddNutritionMenu from './addNutritionMenu';
import NoAccess from '../../../components/reuse/noaccess';
import SweetAlert from 'sweetalert2'
import LoaderBox from '../../../components/reuse/loaderbox';
import { getNutritionMenuTypes } from '../../../redux/nutrition/nutritionMenuType/action';


const NutritionMenuList = (props) => {
  
  const userLocale = General.getLocale();
  const dispatch = useDispatch();

  const userInfo = useSelector(({auth}) => auth.authUser);
  const nutritionMenuData = useSelector(({nutritionMenu}) => nutritionMenu.fetchNutritionMenuData);
  const trainingsData = useSelector(({trainings}) => trainings.trainingsData);
  const nutritionMenuTypeData = useSelector(({nutritionMenuType}) => nutritionMenuType.fetchNutritionMenuTypeData);

  const trans = useSelector(({translation}) => translation.translationsData);
  const userIsAdmin = useSelector(({auth}) => auth.userIsAdmin);

  //SEARCH
  const [searchText, setSearchText] = useState('');
  var searchKeyword = "";

  //DATA
  const [curPage, setCurPage] = useState(0);
  const [email, setEmail] = useState(userInfo != null ? userInfo.email : "");
  const [descr, setDescr] = useState("");


  const addNutritionMenuData = useSelector(({nutritionMenu}) => nutritionMenu.addNutritionMenuData);
  const editNutritionMenuData = useSelector(({nutritionMenu}) => nutritionMenu.editNutritionMenuData);
  const deleteNutritionMenuData = useSelector(({nutritionMenu}) => nutritionMenu.deleteNutritionMenuData);
  const [addEditNutritionMenuModal, setAddEditNutritionMenuModal] = useState(false);
  const [nutritionMenuToEdit, setNutritionMenuToEdit] = useState(null);
  const [nutritionMenuIsEdit, setNutritionMenuIsEdit] = useState(false);

  const [menuTypes,setMenuTypes] = useState([]);

  useEffect(() => {
    if (addNutritionMenuData && addNutritionMenuData.status){
      getMenusData();
        }
    }, [addNutritionMenuData]);

    useEffect(() => {
        if (editNutritionMenuData && editNutritionMenuData.status){
          getMenusData();
        }
    }, [editNutritionMenuData]);


    useEffect(() => {
      if (deleteNutritionMenuData != null){
        if (deleteNutritionMenuData.status){
          toast.success(deleteNutritionMenuData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
            getMenusData();
        } else {
          toast.error(deleteNutritionMenuData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
        }
    
        dispatch({type: DELETE_TRACK, payload: null});
      }
    }, [deleteNutritionMenuData]);


    useEffect(() => {
      getMenusData();
      getMenuTypesData();
    }, []);

    useEffect(() => {
      if (nutritionMenuTypeData != null){
          setMenuTypes(nutritionMenuTypeData.data)
      }
   }, [nutritionMenuTypeData]);
 
  const getMenusData = () => {
    let postData = {fromApp: false}
    dispatch(getNutritionMenu(postData));
  }

  const getMenuTypesData = () => {
    let postData = {}
    dispatch(getNutritionMenuTypes(postData));
}




  const handleUserClick = (e,item) => {
    e.preventDefault();
    setNutritionMenuToEdit(item);
    setNutritionMenuIsEdit(true);
    setAddEditNutritionMenuModal(true);
  }


  const askToDeleteNutritionMenu = (e,item) => {
    e.preventDefault();
    SweetAlert.fire({
      title:trans.delete_dialog_title,
      text:trans.delete_dialog_desc,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText:trans.ok_btn,
      cancelButtonText: trans.cancel_btn,
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
          //OK
            //REMOVE
          let postData = {itemId: item.id}
          dispatch(deleteNutritionMenu(postData));
      }
      else {
        
      }
    })
  }
 
  const getNutritionMenusUI = () => {
    var nutritionMenus = [];

    if (nutritionMenuData == null || nutritionMenuTypeData == null){
      return(<LoaderBox></LoaderBox>)
    }

    nutritionMenuData.data.map((item,i) => {
 
      var menuTypeText = "";
      menuTypes.forEach(element => {
          if (element.numId == item.menuType){
            menuTypeText = element.title;
          }
      });     

      var parentText = "";
      if (item.parent != null){
        parentText = item.parent.title
      }

      nutritionMenus.push(<tr  key={`nutritionMenus_item_${item.id}`} className="_table_nutritionMenus_item">
                    <td>{<Media
                                style={{height:'70px', width:'70px', objectFit:'cover'}}
                                    src={item.imgObj.img_thumb}
                                    alt={`Image Article`}
                                    className="img-thumbnail"
                                />}</td>
                    <td>{item.title}</td>
                    <td>{item.visible == true ? trans.blog_status_published  : trans.blog_status_unpublished}</td>
                    <td>{menuTypeText}</td>
                    <td>{parentText}</td>
                    <td className="text-right">
                            <Button style={{marginRight:'5px'}} onClick={(e) => {askToDeleteNutritionMenu(e,item)}} color="secondary" size="sm"><i className="fa fa-trash"></i> {trans.delete_btn}</Button>  
                            <Button onClick={(e) => {handleUserClick(e,item)}} color="primary" size="sm"><i className="fa fa-pencil"></i> {trans.edit_btn}</Button>
                    </td>
                  </tr>)
      
    });

    if (nutritionMenus.length > 0){
      return (<div className="table-responsive">
      <table className="table card-table table-vcenter text-nowrap">
          <thead>
            <tr className="_table_nutritionMenus_item">
              <th key={"title"}>{trans.nutrition_image}</th>
              <th key={"title"}>{trans.nutrition_menu_type_name}</th>
              <th key={"active"}>{trans.visible}</th>
              <th key={"active"}>{trans.nutrition_menu_type_name}</th>
              <th key={"active"}>{trans.nutrition_menu_category}</th>
              <th key={"nutritionMenu_edit"}></th>
              <th key={"nutritionMenu_delete"}></th>
            </tr>
          </thead>
          <tbody>
            {nutritionMenus}
          </tbody>
        </table>
      </div>);
    } else {
      return (<div className="m-10">
          {trans.no_training_nutritionMenus}
        </div>)
    }

  }

  const addEditModaltoggle = (e) => {
    setNutritionMenuToEdit(null); 
    setNutritionMenuIsEdit(false); 
    setAddEditNutritionMenuModal(!addEditNutritionMenuModal)
  }

  const onSaveNutritionMenu = (data) => {
    if (nutritionMenuIsEdit){
      dispatch(editNutritionMenu(data));
    } else {
      dispatch(addNutritionMenu(data));
    }
  }

  return (
    <Fragment>
      <Breadcrumb parent={trans.nutrition_menus} title={trans.nutrition_menus} />
      <Container fluid={true}>



      {!userIsAdmin  ?
       <Col md="12">
       <NoAccess/>
       </Col>
       : 
        
       <div>
    
              <Card>
                <CardHeader >
                  <Row>
                  <Col xl="12" sm="12"> 
                    <div style={{alignItems: 'center',}}> 
                        <h4 className="card-title mb-0 font-weight-bold" style={{float: "left"}}>{trans.nutrition_menus}</h4>
                        <div className="card-title mb-0" style={{float: "right"}}>
                            <Button onClick={addEditModaltoggle} color="primary" size="sm"><i className="fa fa-pencil"></i> {trans.add_btn}</Button>
                        </div>
                    </div>
                  </Col>
                    </Row>
                </CardHeader>
                <div>
                      {getNutritionMenusUI()}
                    </div>

                </Card>
                
  </div>}
      </Container>
      <AddNutritionMenu
        open={addEditNutritionMenuModal}
        onAddNutritionMenuClose={addEditModaltoggle}
        onSaveNutritionMenu={(data) => onSaveNutritionMenu(data)}
        isEditNutritionMenu={nutritionMenuIsEdit}
        nutritionMenuToEdit={nutritionMenuToEdit}
        trainings={trainingsData != null ? trainingsData.trainings : null}
      />
    </Fragment>
  );
}

export default NutritionMenuList;