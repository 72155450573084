import './nutritionMeal.css';
import React, { Fragment,useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumb from '../../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardFooter, Media, Button, Form, FormGroup, Input, Pagination, PaginationItem, PaginationLink,CardBody,Label } from 'reactstrap'


import * as General from '../../../util/General'
import {getNutritionMeal, addNutritionMeal, deleteNutritionMeal, editNutritionMeal } from '../../../redux/nutrition/nutritionMeal/action';
import { toast } from 'react-toastify';
import AddNutritionMeal from './addNutritionMeal';
import NoAccess from '../../../components/reuse/noaccess';
import SweetAlert from 'sweetalert2'
import LoaderBox from '../../../components/reuse/loaderbox';
import { DELETE_NUTRITION_MEAL } from '../../../redux/actionTypes';


const NutritionMealList = (props) => {
  
  const userLocale = General.getLocale();

  const dispatch = useDispatch();

  const userInfo = useSelector(({auth}) => auth.authUser);
  const nutritionMealData = useSelector(({nutritionMeal}) => nutritionMeal.fetchNutritionMealData);
  const trainingsData = useSelector(({trainings}) => trainings.trainingsData);
 

  const trans = useSelector(({translation}) => translation.translationsData);
  const userIsAdmin = useSelector(({auth}) => auth.userIsAdmin);

  //SEARCH
  const [searchText, setSearchText] = useState('');
  var searchKeyword = "";

  //DATA
  const [curPage, setCurPage] = useState(0);
  const [email, setEmail] = useState(userInfo != null ? userInfo.email : "");
  const [descr, setDescr] = useState("");


  const addNutritionMealData = useSelector(({nutritionMeal}) => nutritionMeal.addNutritionMealData);
  const editNutritionMealData = useSelector(({nutritionMeal}) => nutritionMeal.editNutritionMealData);
  const deleteNutritionMealData = useSelector(({nutritionMeal}) => nutritionMeal.deleteNutritionMealData);
  const [addEditNutritionMealModal, setAddEditNutritionMealModal] = useState(false);
  const [nutritionMealToEdit, setNutritionMealToEdit] = useState(null);
  const [nutritionMealIsEdit, setNutritionMealIsEdit] = useState(false);

  const [menuTypes,setMenuTypes] = useState([]);

  useEffect(() => {
    if (addNutritionMealData && addNutritionMealData.status){
      getMenusData();
        }
    }, [addNutritionMealData]);

    useEffect(() => {
        if (editNutritionMealData && editNutritionMealData.status){
          getMenusData();
        }



    }, [editNutritionMealData]);


    useEffect(() => {
      if (deleteNutritionMealData != null){
        if (deleteNutritionMealData.status){
          toast.success(deleteNutritionMealData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
            getMenusData();
        } else {
          toast.error(deleteNutritionMealData.message, {
            position: toast.POSITION.TOP_RIGHT
            });
        }
    
        dispatch({type: DELETE_NUTRITION_MEAL, payload: null});
      }
    }, [deleteNutritionMealData]);


    useEffect(() => {
      getMenusData();
    }, []);

 
  const getMenusData = () => {
    let postData = {fromApp: false}
    dispatch(getNutritionMeal(postData));
  }





  const handleUserClick = (e,item) => {
    e.preventDefault();
    setNutritionMealToEdit(item);
    setNutritionMealIsEdit(true);
    setAddEditNutritionMealModal(true);
  }


  const askToDeleteNutritionMeal = (e,item) => {
    e.preventDefault();
    SweetAlert.fire({
      title:trans.delete_dialog_title,
      text:trans.delete_dialog_desc,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText:trans.ok_btn,
      cancelButtonText: trans.cancel_btn,
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
          //OK
            //REMOVE
          let postData = {itemId: item.id}
          dispatch(deleteNutritionMeal(postData));
      }
      else {
        
      }
    })
  }
 
  const getNutritionMealsUI = () => {
    var nutritionMeals = [];

    if (nutritionMealData == null){
      return(<LoaderBox></LoaderBox>)
    }

    nutritionMealData.data.map((item,i) => {
 
      var menuTypeText = "";

      var basicMenu = "";

      var planText = "";

      if (item.menuId != null){
        if (item.menuId != null){
          menuTypeText = item.menuId.title;
        }
        if (item.menuId.parent != null){
          basicMenu = item.menuId.parent.title;
        }
       
     
        
      }

      if (item.planId != null){
        planText = item.planId.title;
      }




      nutritionMeals.push(<tr  key={`nutritionMeals_item_${item.id}`} className="_table_nutritionMeals_item">
                    <td>{item.title}</td>
                    <td>{item.visible == true ? trans.blog_status_published  : trans.blog_status_unpublished}</td>
                    <td>{basicMenu}</td>
                    <td>{menuTypeText}</td>
                    <td>{planText}</td>
                    <td>{item.caloriesText}</td>
                    <td className="text-right">
                            <Button style={{marginRight:'5px'}} onClick={(e) => {askToDeleteNutritionMeal(e,item)}} color="secondary" size="sm"><i className="fa fa-trash"></i> {trans.delete_btn}</Button>  
                            <Button onClick={(e) => {handleUserClick(e,item)}} color="primary" size="sm"><i className="fa fa-pencil"></i> {trans.edit_btn}</Button>
                    </td>
                  </tr>)
      
    });

    if (nutritionMeals.length > 0){
      return (<div className="table-responsive">
      <table className="table card-table table-vcenter text-nowrap">
          <thead>
            <tr className="_table_nutritionMeals_item">
              <th key={"title"}>{trans.nutrition_title}</th>
              <th key={"active"}>{trans.visible}</th>
              <th key={"item.planId.basicmenu"}>{trans.nutrition_basic_menu}</th>
              <th key={"item.menuId.title"}>{trans.nutrition_menu_category}</th>
              <th key={"item.planId.title"}>{trans.nutrition_calories}</th>
              <th key={"item.planId.title"}>{trans.nutrition_plan_type}</th>
              <th key={"nutritionMeal_edit"}></th>
              <th key={"nutritionMeal_delete"}></th>
            </tr>
          </thead>
          <tbody>
            {nutritionMeals}
          </tbody>
        </table>
      </div>);
    } else {
      return (<div className="m-10">
          {trans.no_training_nutritionMeals}
        </div>)
    }

  }

  const addEditModaltoggle = (e) => {
    setNutritionMealToEdit(null); 
    setNutritionMealIsEdit(false); 
    setAddEditNutritionMealModal(!addEditNutritionMealModal)
  }

  const onSaveNutritionMeal = (data) => {
    if (nutritionMealIsEdit){
      dispatch(editNutritionMeal(data));
    } else {
      dispatch(addNutritionMeal(data));
    }
  }

  return (
    <Fragment>
      <Breadcrumb parent={trans.nutrition_meals} title={trans.nutrition_meals} />
      <Container fluid={true}>



      {!userIsAdmin  ?
       <Col md="12">
       <NoAccess/>
       </Col>
       : 
        
       <div>
    
              <Card>
                <CardHeader >
                  <Row>
                  <Col xl="12" sm="12"> 
                    <div style={{alignItems: 'center',}}> 
                        <h4 className="card-title mb-0 font-weight-bold" style={{float: "left"}}>{trans.nutrition_meals}</h4>
                        <div className="card-title mb-0" style={{float: "right"}}>
                            <Button onClick={addEditModaltoggle} color="primary" size="sm"><i className="fa fa-pencil"></i> {trans.add_btn}</Button>
                        </div>
                    </div>
                  </Col>
                    </Row>
                </CardHeader>
                <div>
                      {getNutritionMealsUI()}
                    </div>

                </Card>
                
  </div>}
      </Container>
      <AddNutritionMeal
        open={addEditNutritionMealModal}
        onAddNutritionMealClose={addEditModaltoggle}
        onSaveNutritionMeal={(data) => onSaveNutritionMeal(data)}
        isEditNutritionMeal={nutritionMealIsEdit}
        nutritionMealToEdit={nutritionMealToEdit}
        trainings={trainingsData != null ? trainingsData.trainings : null}
      />
    </Fragment>
  );
}

export default NutritionMealList;